import styled from 'styled-components';

export const WidgetContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .green1 {
    background-color: var(--green1);
    color: white;
  }
  .green2 {
    background-color: var(--green2);
    color: white;
  }
`;

export const Widget = styled.section`
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  &:last-of-type {
    margin-right: 0px;
  }
`;

export const WidgetTitle = styled.h3`
  font-size: 28px;
  color: var(--black);
  margin-bottom: 5px;
`;

export const WidgetNumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    max-width: 100px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const WidgetNumber = styled.h3`
  font-weight: 700;
  font-size: 68px;
  margin: 0 15px 0 0;
`;
