import styled from 'styled-components';

export const ProjectUser = styled.div`
  padding: 10px;
  background-color: var(--gray-lighter);
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;

export const ProjectUserContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  svg {
    cursor: pointer;
  }
`;
