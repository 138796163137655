/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../../../components/Button';
import { useModal } from '../../../hooks/Modal';
import { useProject } from '../../../hooks/projects';
import { LoadingIcon } from '../../../styles/global';
import {
  ButtonsContainer,
  Stepblock,
  Stepblocks,
  StepMoveable,
  StepName,
  StepOptions,
} from '../Project/styles';

import Container, {
  ErrorText,
  StepEditingContainer,
  StepRadios,
  CreateStep,
  CreateStepContainer,
  CreateStepError,
  CreateStepRadios,
} from './styles';

interface Status {
  status: 'ongoing' | 'finished' | 'stuck' | 'standby';
}

const Step: React.FC = () => {
  const {
    projectEditing,
    changeStepOrder,
    removeStep,
    updateSteps,
    createStep,
  } = useProject();
  const { showModal, hideModal } = useModal();

  const selectStepblockRef = useRef<HTMLSelectElement>(null);

  const stepIdInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepNameInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepDescriptionInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepBasecampInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepOtherInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepOrderInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepStatusStandbyInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepStatusOngoingInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepStatusFinishedInputRefs = useRef<Array<HTMLInputElement | null>>(
    [],
  );
  const stepStatusStuckInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const stepResponsibleInputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const stepCreateNameRef = useRef<HTMLInputElement>(null);
  const stepCreateDescriptionRef = useRef<HTMLInputElement>(null);
  const stepCreateBasecampRef = useRef<HTMLInputElement>(null);
  const stepCreateOtherRef = useRef<HTMLInputElement>(null);
  const stepCreateStatusOngoingRef = useRef<HTMLInputElement>(null);
  const stepCreateStatusStuckRef = useRef<HTMLInputElement>(null);
  const stepCreateStatusFinishedRef = useRef<HTMLInputElement>(null);
  const stepCreateResponsibleRef = useRef<HTMLInputElement>(null);

  const [stepblockId, setStepblockId] = useState('');
  const [savingSteps, setSavingSteps] = useState(false);
  const [createStepError, setCreateStepError] = useState('');

  function stepblockChange() {
    const id = selectStepblockRef.current?.value;
    if (id) {
      setStepblockId(id);
    }
  }

  const renderStepblockOptions = useMemo(() => {
    if (projectEditing.stepblocks) {
      return projectEditing.stepblocks.map(stepblock => {
        return (
          <option key={stepblock.id} value={stepblock.id}>
            {stepblock.block_name}
          </option>
        );
      });
    }
    return false;
  }, [projectEditing.stepblocks]);

  const stepMoveUp = useCallback(
    (step_id: string, stepblock_id: string, actual_order: number) => {
      changeStepOrder(step_id, stepblock_id, actual_order - 1);
    },
    [changeStepOrder],
  );

  const stepMoveDown = useCallback(
    (step_id: string, stepblock_id: string, actual_order: number) => {
      changeStepOrder(step_id, stepblock_id, actual_order + 1);
    },
    [changeStepOrder],
  );

  const stepRemove = useCallback(
    (stepblock_id: string, step_id: string) => {
      setSavingSteps(true);
      hideModal();
      removeStep(stepblock_id, step_id).finally(() => {
        setSavingSteps(false);
      });
    },
    [hideModal, removeStep],
  );

  const removeStepModal = useCallback(
    (stepblock_id: string, step_id: string, block_name: string) => {
      showModal({
        content: (
          <>
            <p>{`Nome do passo: ${block_name}`}</p>
            <div>
              <Button onClick={() => stepRemove(stepblock_id, step_id)}>
                Sim, remover
              </Button>
              <Button onClick={() => hideModal()}>Cancelar</Button>
            </div>
          </>
        ),
        title: 'Confirma a remoção do passo?',
      });
    },
    [hideModal, showModal, stepRemove],
  );

  const handleStepRemove = useCallback(
    (stepblock_id: string, step_id: string, block_name: string) => {
      removeStepModal(stepblock_id, step_id, block_name);
    },
    [removeStepModal],
  );

  const renderSteps = useMemo(() => {
    if (projectEditing.stepblocks) {
      if (stepblockId) {
        const steps = projectEditing.stepblocks.find(stepblock => {
          return stepblock.id === stepblockId;
        })?.steps;
        if (steps?.length === 0) {
          return (
            <ErrorText>Não existem passos criados para esta etapa.</ErrorText>
          );
        }
        return steps
          ?.sort((stepA, stepB) => {
            return stepA.order - stepB.order;
          })
          .map((step, idx) => {
            return (
              <Stepblock key={step.id}>
                <StepMoveable>
                  {step.order > 1 && (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      fixedWidth
                      onClick={() =>
                        stepMoveUp(step.id, stepblockId, step.order)
                      }
                    />
                  )}
                  {step.order === 1 && (
                    <FontAwesomeIcon
                      className="chevron-disabled"
                      icon={faChevronUp}
                      fixedWidth
                    />
                  )}
                  <span>{step.order}</span>
                  {step.order < steps.length && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fixedWidth
                      onClick={() =>
                        stepMoveDown(step.id, stepblockId, step.order)
                      }
                    />
                  )}
                  {step.order === steps.length && (
                    <FontAwesomeIcon
                      className="chevron-disabled"
                      icon={faChevronDown}
                      fixedWidth
                    />
                  )}
                </StepMoveable>
                <StepName>
                  <input
                    ref={input => (stepNameInputRefs.current[idx] = input)}
                    type="text"
                    name="block_name"
                    id={step.id}
                    defaultValue={step.step_name}
                  />
                </StepName>
                <StepOptions>
                  <FontAwesomeIcon
                    icon={faTrash}
                    fixedWidth
                    onClick={() =>
                      handleStepRemove(stepblockId, step.id, step.step_name)
                    }
                  />
                </StepOptions>
                <StepEditingContainer>
                  <input
                    ref={input => (stepIdInputRefs.current[idx] = input)}
                    type="hidden"
                    id={step.id}
                    defaultValue={step.id}
                  />
                  <input
                    ref={input => (stepOrderInputRefs.current[idx] = input)}
                    type="hidden"
                    id={`step_order_${step.id}`}
                    defaultValue={step.order}
                  />
                  <label htmlFor={`step_description_${step.id}`}>
                    Descrição do passo
                  </label>
                  <input
                    ref={input =>
                      (stepDescriptionInputRefs.current[idx] = input)
                    }
                    type="text"
                    name="step_description"
                    id={`step_description_${step.id}`}
                    defaultValue={step.step_description}
                  />
                  <label htmlFor={`link_basecamp_${step.id}`}>
                    Link do tópico no Basecamp (opcional)
                  </label>
                  <input
                    ref={input => (stepBasecampInputRefs.current[idx] = input)}
                    type="text"
                    name="link_basecamp"
                    id={`link_basecamp_${step.id}`}
                    defaultValue={step.link_basecamp}
                  />
                  <label htmlFor={`link_other_${step.id}`}>
                    Link secundário (opcional)
                  </label>
                  <input
                    ref={input => (stepOtherInputRefs.current[idx] = input)}
                    type="text"
                    name="link_other"
                    id={`link_other_${step.id}`}
                    defaultValue={step.link_other}
                  />
                  <label htmlFor={`step_radios_${step.id}`}>Status</label>
                  <StepRadios id={`step_radios_${step.id}`}>
                    <div>
                      <input
                        ref={input =>
                          (stepStatusStandbyInputRefs.current[idx] = input)
                        }
                        type="radio"
                        name={`status_${step.id}`}
                        id={`status_standby_${step.id}`}
                        defaultChecked={step.status === 'standby'}
                      />
                      <label htmlFor={`status_standby_${step.id}`}>
                        Aguardando
                      </label>
                    </div>
                    <div>
                      <input
                        ref={input =>
                          (stepStatusOngoingInputRefs.current[idx] = input)
                        }
                        type="radio"
                        name={`status_${step.id}`}
                        id={`status_ongoing_${step.id}`}
                        defaultChecked={step.status === 'ongoing'}
                      />
                      <label htmlFor={`status_ongoing_${step.id}`}>
                        Em andamento
                      </label>
                    </div>
                    <div>
                      <input
                        ref={input =>
                          (stepStatusStuckInputRefs.current[idx] = input)
                        }
                        type="radio"
                        name={`status_${step.id}`}
                        id={`status_stuck_${step.id}`}
                        defaultChecked={step.status === 'stuck'}
                      />
                      <label htmlFor={`status_stuck_${step.id}`}>
                        Aguardando cliente
                      </label>
                    </div>
                    <div>
                      <input
                        ref={input =>
                          (stepStatusFinishedInputRefs.current[idx] = input)
                        }
                        type="radio"
                        name={`status_${step.id}`}
                        id={`status_finished_${step.id}`}
                        defaultChecked={step.status === 'finished'}
                      />
                      <label htmlFor={`status_finished_${step.id}`}>
                        Finalizada
                      </label>
                    </div>
                  </StepRadios>
                  <label htmlFor={`step_responsible_${step.id}`}>
                    Responsabilidade
                  </label>
                  <StepRadios id={`step_responsible_${step.id}`}>
                    <div>
                      <input
                        ref={input =>
                          (stepResponsibleInputRefs.current[idx] = input)
                        }
                        type="checkbox"
                        name={`responsible_${step.id}`}
                        id={`responsible_${step.id}`}
                        defaultChecked={step.client}
                      />
                      <label htmlFor={`responsible_${step.id}`}>
                        O cliente é responsável por esse passo?
                      </label>
                    </div>
                  </StepRadios>
                </StepEditingContainer>
              </Stepblock>
            );
          });
      }
    }
    return null;
  }, [
    handleStepRemove,
    projectEditing.stepblocks,
    stepMoveDown,
    stepMoveUp,
    stepblockId,
  ]);

  function isStep<T>(argument: T | boolean): argument is T {
    return argument !== false;
  }

  const saveSteps = useCallback(() => {
    setSavingSteps(true);
    const stepsToUpdate = stepIdInputRefs.current
      .map((idInput, idx) => {
        if (idInput) {
          if (stepNameInputRefs.current[idx] === null) {
            return false;
          }

          let { status }: Status = { status: 'standby' };

          if (stepStatusFinishedInputRefs.current[idx]!.checked) {
            status = 'finished';
          } else if (stepStatusStuckInputRefs.current[idx]!.checked) {
            status = 'stuck';
          } else if (stepStatusOngoingInputRefs.current[idx]!.checked) {
            status = 'ongoing';
          }

          let clientResponsible = false;

          if (stepResponsibleInputRefs.current[idx]!.checked) {
            clientResponsible = true;
          }

          const id = idInput?.value;
          const stepToUpdate = {
            step_id: id,
            step_name: stepNameInputRefs.current[idx]!.value,
            step_description: stepDescriptionInputRefs.current[idx]!.value,
            link_basecamp: stepBasecampInputRefs.current[idx]!.value,
            link_other: stepOtherInputRefs.current[idx]!.value,
            order: Number(stepOrderInputRefs.current[idx]!.value),
            status,
            client: clientResponsible,
          };

          return stepToUpdate;
        }
        return false;
      })
      .filter(isStep);

    updateSteps(stepblockId, { stepsToUpdate }).finally(() =>
      setSavingSteps(false),
    );
  }, [stepblockId, updateSteps]);

  const handleCreateStep = useCallback(() => {
    setCreateStepError('');
    const step_name = stepCreateNameRef.current?.value;
    if (!step_name) {
      setCreateStepError('Digite o nome do passo');
      return;
    }
    const step_description = stepCreateDescriptionRef.current?.value;
    if (!step_description) {
      setCreateStepError('Digite a descrição do passo');
      return;
    }
    const link_basecamp = stepCreateBasecampRef.current?.value;
    const link_other = stepCreateOtherRef.current?.value;

    let { status }: Status = { status: 'standby' };

    if (stepCreateStatusFinishedRef.current?.checked) {
      status = 'finished';
    } else if (stepCreateStatusStuckRef.current?.checked) {
      status = 'stuck';
    } else if (stepCreateStatusOngoingRef.current?.checked) {
      status = 'ongoing';
    }
    setSavingSteps(true);

    let clientResponsible = false;

    if (stepCreateResponsibleRef.current?.checked) {
      clientResponsible = true;
    }

    createStep(
      {
        step_name,
        step_description,
        link_basecamp,
        link_other,
        client: clientResponsible,
        status,
      },
      stepblockId,
    ).finally(() => {
      setSavingSteps(false);
      stepCreateNameRef.current!.value = '';
      stepCreateDescriptionRef.current!.value = '';
      stepCreateBasecampRef.current!.value = '';
      stepCreateOtherRef.current!.value = '';
      stepCreateResponsibleRef.current!.checked = false;
    });
  }, [createStep, stepblockId]);

  return (
    <>
      <h1>Edição dos passos</h1>
      <Container>
        {!projectEditing.stepblocks && (
          <LoadingIcon>
            <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
          </LoadingIcon>
        )}
        {projectEditing.stepblocks && (
          <>
            <label htmlFor="stepblock_selector">
              Escolha abaixo a etapa que deseja editar
            </label>
            <select
              name="stepblock_selector"
              id="stepblock_selector"
              onChange={stepblockChange}
              ref={selectStepblockRef}
            >
              <option value="">Selecione a etapa</option>
              {renderStepblockOptions}
            </select>
            {stepblockId && (
              <>
                <Stepblocks>{renderSteps}</Stepblocks>
                <CreateStepContainer>
                  <label htmlFor="create_step_name">Criar um novo passo</label>
                  <CreateStep>
                    <input
                      type="text"
                      name="create_step_name"
                      id="create_step_name"
                      placeholder="Nome do passo"
                      ref={stepCreateNameRef}
                    />
                    <input
                      type="text"
                      name="create_step_description"
                      id="create_step_description"
                      placeholder="Descrição do passo"
                      ref={stepCreateDescriptionRef}
                    />
                    <input
                      type="text"
                      name="create_step_link_basecamp"
                      id="create_step_link_basecamp"
                      placeholder="Link do tópico no Basecamp, opcional"
                      ref={stepCreateBasecampRef}
                    />
                    <input
                      type="text"
                      name="create_step_link_other"
                      id="create_step_link_other"
                      placeholder="Link secundário, opcional"
                      ref={stepCreateOtherRef}
                    />
                    <CreateStepRadios>
                      <p>Status:</p>
                      <div>
                        <input
                          type="radio"
                          name="create_step_status"
                          id="create_step_status_standby"
                        />
                        <label htmlFor="create_step_status_standby">
                          Aguardando
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="create_step_status"
                          id="create_step_status_ongoing"
                          ref={stepCreateStatusOngoingRef}
                        />
                        <label htmlFor="create_step_status_ongoing">
                          Em andamento
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="create_step_status"
                          id="create_step_status_stuck"
                          ref={stepCreateStatusStuckRef}
                        />
                        <label htmlFor="create_step_status_stuck">
                          Aguardando cliente
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="create_step_status"
                          id="create_step_status_finished"
                          ref={stepCreateStatusFinishedRef}
                        />
                        <label htmlFor="create_step_status_finished">
                          Finalizado
                        </label>
                      </div>
                    </CreateStepRadios>
                    <CreateStepRadios>
                      <p>Responsabilidade:</p>
                      <div>
                        <input
                          type="checkbox"
                          name="create_step_responsible"
                          id="create_step_responsible"
                          ref={stepCreateResponsibleRef}
                        />
                        <label htmlFor="create_step_responsible">
                          O cliente é responsável por esse passo?
                        </label>
                      </div>
                    </CreateStepRadios>
                    <Button
                      className="btn btn-primary btn-small"
                      onClick={() => handleCreateStep()}
                    >
                      Criar passo
                    </Button>
                  </CreateStep>
                  {createStepError.length > 0 && (
                    <CreateStepError>{createStepError}</CreateStepError>
                  )}
                </CreateStepContainer>
                <ButtonsContainer>
                  <Button
                    className="btn btn-primary"
                    isLoading={savingSteps}
                    onClick={() => saveSteps()}
                  >
                    Salvar passos
                  </Button>
                </ButtonsContainer>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Step;
