import {
  faChartBar,
  faHome,
  faLock,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

interface MenuProps {
  items: {
    type: 'link' | 'a';
    url: string;
    icon: IconDefinition;
    text: string;
  }[];
  itemsProject: {
    type: 'link' | 'a';
    url: string;
    icon: IconDefinition;
    text: string;
  }[];
}

const ClientMenuObjects: MenuProps = {
  items: [
    {
      type: 'link',
      url: '/dashboard',
      icon: faHome,
      text: 'Início',
    },
    {
      type: 'link',
      url: '/change-password',
      icon: faLock,
      text: 'Alterar senha',
    },
  ],
  itemsProject: [
    {
      type: 'link',
      url: '/dashboard',
      icon: faHome,
      text: 'Início',
    },
    {
      type: 'link',
      url: '/results',
      icon: faChartBar,
      text: 'Resultados',
    },
    {
      type: 'link',
      url: '/change-password',
      icon: faLock,
      text: 'Alterar senha',
    },
  ],
};

export default ClientMenuObjects;
