/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import {
  Container,
  PaginationContainer,
  ListHeadings,
  SearchContainer,
} from './styles';
import { LoadingIcon } from '../../styles/global';
import User from '../../entities/User';
import { useUsers } from '../../hooks/users';

interface PaginationProps {
  items: User[];
  itemsPerPage: number;
}

const AdminDashUserListPaginated: React.FC<PaginationProps> = ({
  items,
  itemsPerPage,
}: PaginationProps) => {
  const [length, setLength] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const { clearUserForEdit } = useUsers();

  const history = useHistory();

  const searchRef = useRef<HTMLInputElement>(null);
  const clientesRef = useRef<HTMLInputElement>(null);
  const adminsRef = useRef<HTMLInputElement>(null);

  const [filterBy, setFilterBy] = useState('');

  const updateFilter = useCallback(
    (type: string) => {
      if (filterBy !== type) {
        setFilterBy(type);
      } else {
        setFilterBy('');
      }

      if (type === 'admins') {
        clientesRef.current!.checked = false;
      } else {
        adminsRef.current!.checked = false;
      }
    },
    [filterBy],
  );

  useEffect(() => {
    setLength(items.length);
  }, [items]);

  useEffect(() => {
    const newLength = items
      .filter(item => {
        if (filterBy.length > 0) {
          if (filterBy === 'admins') {
            return item.admin === true;
          }

          return item.client === true;
        }
        return item;
      })
      .filter(item => {
        if (searchTerm.length > 0) {
          return (
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            item.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
        }
        return item;
      }).length;
    setLength(newLength);
    setActualPage(1);
  }, [items, searchTerm, filterBy]);

  const pages = useMemo(() => {
    return Math.ceil(length / itemsPerPage);
  }, [itemsPerPage, length]);

  const changePage = useCallback((i: number) => {
    setActualPage(i);
  }, []);

  const prevPage = useCallback(() => {
    setActualPage(actualPage - 1);
  }, [actualPage]);

  const nextPage = useCallback(() => {
    setActualPage(actualPage + 1);
  }, [actualPage]);

  const pagination = useMemo(() => {
    const paginationElements: React.ReactNode[] = [];

    paginationElements.push(
      <button
        key="prevPage"
        className="prevButton"
        type="button"
        onClick={() => prevPage()}
        disabled={actualPage <= 1}
      >
        &lt;
      </button>,
    );
    for (let i = 0; i < pages; i += 1) {
      paginationElements.push(
        <button
          key={`page-${i}`}
          className={`pageButton ${
            actualPage === i + 1 ? 'navButtonSelected' : ''
          }`}
          type="button"
          onClick={() => changePage(i + 1)}
        >
          {`${i + 1}`}
        </button>,
      );
    }
    paginationElements.push(
      <button
        key="nextPage"
        className="nextButton"
        type="button"
        onClick={() => nextPage()}
        disabled={actualPage >= pages}
      >
        &gt;
      </button>,
    );
    return paginationElements;
  }, [actualPage, changePage, nextPage, pages, prevPage]);

  const setUserForEditing = useCallback(
    (user_id: string) => {
      clearUserForEdit();
      history.push(`/admin/user?user_id=${user_id}`);
    },
    [clearUserForEdit, history],
  );

  const renderItems = useMemo(() => {
    return items
      .filter(item => {
        if (filterBy.length > 0) {
          if (filterBy === 'admins') {
            return item.admin === true;
          }

          return item.client === true;
        }
        return item;
      })
      .filter(item => {
        if (searchTerm.length > 0) {
          return (
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            item.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
        }
        return item;
      })
      .filter((item, index) => {
        return (
          index >= (actualPage - 1) * itemsPerPage &&
          index <= actualPage * itemsPerPage - 1
        );
      })
      .map(item => {
        return (
          <button
            type="button"
            className="project"
            key={item.id}
            onClick={() => setUserForEditing(item.id)}
          >
            <div style={{ maxWidth: '250px', minWidth: '150px' }}>
              {item.name}
            </div>
            <div style={{ maxWidth: '250px', minWidth: '200px' }}>
              {item.email}
            </div>
            <div style={{ maxWidth: '100px', textAlign: 'center' }}>
              {item.admin === true ? 'Admin' : 'Cliente'}
            </div>
            <div style={{ textAlign: 'right' }}>
              {format(new Date(item.created_at), `dd'/'MM'/'yyyy`, {
                locale: ptBR,
              })}
            </div>
          </button>
        );
      });
  }, [
    actualPage,
    filterBy,
    items,
    itemsPerPage,
    searchTerm,
    setUserForEditing,
  ]);

  const handleSearch = useCallback(() => {
    if (searchRef.current) {
      setSearchTerm(searchRef.current.value);
    }
  }, []);

  return (
    <Container>
      {/* <p>{`Items: ${length} | Pages: ${pages} | Actual page: ${actualPage}`}</p> */}
      <SearchContainer>
        <div>
          <input
            ref={searchRef}
            type="text"
            name="search"
            id="search"
            onChange={handleSearch}
            placeholder="Pesquisar por nome ou e-mail"
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className="search-checkbox">
          <input
            type="checkbox"
            name="clients"
            id="clients"
            ref={clientesRef}
            onChange={() => updateFilter('clients')}
          />
          <label htmlFor="clients">Clientes</label>
        </div>
        <div className="search-checkbox">
          <input
            type="checkbox"
            name="admins"
            id="admins"
            ref={adminsRef}
            onChange={() => updateFilter('admins')}
          />
          <label htmlFor="admins">Admins</label>
        </div>
      </SearchContainer>
      <ListHeadings>
        <div style={{ maxWidth: '250px', minWidth: '150px' }}>Nome</div>
        <div style={{ maxWidth: '250px', minWidth: '200px' }}>E-mail</div>
        <div style={{ maxWidth: '100px', textAlign: 'center' }}>Tipo</div>
        <div style={{ textAlign: 'right' }}>Data de criação</div>
      </ListHeadings>
      {items.length === 0 && (
        <LoadingIcon>
          <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
        </LoadingIcon>
      )}
      {renderItems}
      <PaginationContainer className="pagination-container">
        {pagination}
      </PaginationContainer>
    </Container>
  );
};

export default AdminDashUserListPaginated;
