import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

import Container, { SuccessEffect } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  isLoading?: boolean;
  isErrored?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className = '',
  children,
  isLoading = false,
  isErrored = false,
  ...rest
}) => {
  const [changedLoading, setChangedLoading] = useState(false);
  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (!isLoading && changedLoading) {
      setOk(true);
      setTimeout(() => {
        setOk(false);
        setChangedLoading(false);
      }, 2000);
    } else if (isLoading && !changedLoading) {
      setChangedLoading(true);
    }
  }, [isLoading, changedLoading]);

  return (
    <Container className={className} disabled={!!isLoading} {...rest}>
      {!isLoading && !ok && children}
      {!isLoading && !isErrored && ok && <FontAwesomeIcon icon={faCheck} />}
      {!isLoading && !isErrored && ok && <SuccessEffect />}
      {!isLoading && isErrored && ok && <FontAwesomeIcon icon={faTimes} />}
      {!!isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
    </Container>
  );
};

export default Button;
