import React from 'react';

import Container from './styles';

export interface BadgeProps {
  color: 'green' | 'yellow' | 'gray';
  className: string;
}

const Badge: React.FC<BadgeProps> = ({ color, className, children }) => {
  return (
    <Container color={color} className={`badge ${className}`}>
      {children}
    </Container>
  );
};

export default Badge;
