import styled, { keyframes } from 'styled-components';

const Container = styled.button`
  border: none;
  padding: 10px 15px;
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
  color: var(--black);
  position: relative;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    opacity: 0.6;
  }
`;

export default Container;

const EffectAnimationSuccess = keyframes`
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.9);
  }
  100% {
    box-shadow: 0;
  }
`;

export const SuccessEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 10px;
  animation-name: ${EffectAnimationSuccess};
  animation-duration: 2000ms;
  animation-timing-function: ease-out;
`;
