import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';
import Project from '../entities/Project';
import ProjectFull, { Stepblock } from '../entities/ProjectFull';
import { ProjectFull2, Stepjson } from '../entities/Stepjson';

interface ProjectState {
  projects: Project[];
}

interface StepUpdateData {
  stepsToUpdate: {
    step_id: string;
    step_name: string;
    step_description: string;
    link_basecamp: string;
    link_other: string;
    order: number;
    status: 'ongoing' | 'finished' | 'stuck' | 'standby';
    client: boolean;
  }[];
}

interface StepCreateData {
  step_name: string;
  step_description: string;
  link_basecamp?: string;
  link_other?: string;
  status: 'ongoing' | 'finished' | 'stuck' | 'standby';
  client: boolean;
}

interface ProjectContextData {
  projects: Project[];
  projectEditing: ProjectFull;
  setProjectForEdit(project_id: string): void;
  clearProjectForEdit(): void;
  changeStepblockOrder(stepblock_id: string, order: number): void;
  changeStepblockName(stepblock_id: string, name: string): void;
  updateStepblocks(): Promise<void>;
  removeStepblock(stepblock_id: string): Promise<void>;
  createStepblock(stepblock_name: string): Promise<void>;
  changeStepOrder(step_id: string, stepblock_id: string, order: number): void;
  updateSteps(stepblock_id: string, stepData: StepUpdateData): Promise<void>;
  removeStep(stepblock_id: string, step_id: string): Promise<void>;
  createStep(stepData: StepCreateData, stepblock_id: string): Promise<void>;
  saveProject(project_name: string, company_name: string): Promise<void>;
  createProject(project_name: string, company_name: string): Promise<string>;
}

export const ProjectContext = createContext<ProjectContextData>(
  {} as ProjectContextData,
);

export const ProjectProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ProjectState>({ projects: [] });
  const [projectEditing, setProjectEditing] = useState<ProjectFull>(
    {} as ProjectFull,
  );
  const [projectEditing2, setProjectEditing2] = useState<ProjectFull2>(
    {} as ProjectFull2,
  );
  const [stepjson, setStepjson] = useState<Stepjson>({} as Stepjson);

  useEffect(() => {
    api.get('/projects').then(response => {
      setData({
        projects: response.data,
      });
    });
  }, []);

  useEffect(() => {
    async function updateStatus(status: 'ongoing' | 'finished' | 'standby') {
      await api.patch(`/projects/${projectEditing.id}/status`, {
        status,
      });
      setProjectEditing({
        ...projectEditing,
        status,
      });
      const newProjects = data.projects.map(project => {
        if (project.id === projectEditing.id) {
          return {
            ...project,
            status,
          };
        }
        return project;
      });
      setData({
        projects: newProjects,
      });
    }
    if (projectEditing.stepblocks) {
      const stepblocksStatus = projectEditing.stepblocks.map(stepblock => {
        if (!stepblock.steps) return undefined;
        if (stepblock.steps.length === 0) return undefined;

        const stepsStatus = stepblock.steps.map(step => {
          return step.status;
        });

        const somethingStuckOrOngoing = stepsStatus.find(status => {
          return status === 'ongoing' || status === 'stuck';
        });

        if (somethingStuckOrOngoing) {
          return 'ongoing';
        }

        const somethingStandby = stepsStatus.find(status => {
          return status === 'standby';
        });

        if (somethingStandby) {
          return 'standby';
        }

        return 'finished';
      });

      const setOngoing = stepblocksStatus.find(status => {
        return status === 'ongoing';
      });

      const setStandby = stepblocksStatus.find(status => {
        return status === 'standby';
      });

      const everythingUndefined = stepblocksStatus.find(status => {
        return status !== undefined;
      });

      if (!everythingUndefined) {
        return;
      }

      if (!setOngoing && !setStandby && projectEditing.status !== 'finished') {
        updateStatus('finished');
      }

      if (!setOngoing && setStandby && projectEditing.status !== 'standby') {
        updateStatus('standby');
      }

      if (setOngoing && projectEditing.status !== 'ongoing') {
        updateStatus('ongoing');
      }
    }
  }, [data.projects, projectEditing]);

  const createProject = useCallback(
    async (project_name: string, company_name: string): Promise<string> => {
      const response = await api.post('/projects', {
        project_name,
        company_name,
      });

      const newProject: ProjectFull = {
        ...response.data,
        stepblocks: [],
      };

      setData({
        projects: [newProject, ...data.projects],
      });

      return newProject.id;
    },
    [data.projects],
  );

  const setProjectForEdit = useCallback(project_id => {
    api.get(`/projects/${project_id}/steps`).then(response => {
      setProjectEditing(response.data);
    });
  }, []);

  const clearProjectForEdit = useCallback(() => {
    setProjectEditing({} as ProjectFull);
  }, []);

  const changeStepblockOrder = useCallback(
    (stepblock_id: string, order: number) => {
      let actualOrder = 0;
      const newStepblocks = projectEditing.stepblocks.map(stepblock => {
        if (stepblock.id === stepblock_id) {
          actualOrder = stepblock.order;
          return {
            ...stepblock,
            order,
          };
        }
        return stepblock;
      });

      const stepblocksOrdered = newStepblocks.map(stepblock => {
        if (stepblock.id !== stepblock_id) {
          if (actualOrder < order) {
            if (stepblock.order === order) {
              return {
                ...stepblock,
                order: stepblock.order - 1,
              };
            }
          } else if (stepblock.order === order) {
            return {
              ...stepblock,
              order: stepblock.order + 1,
            };
          }
        }
        return stepblock;
      });

      setProjectEditing({
        ...projectEditing,
        stepblocks: stepblocksOrdered,
      });
    },
    [projectEditing],
  );

  const changeStepOrder = useCallback(
    (step_id: string, stepblock_id: string, order: number) => {
      const stepblockFinded = projectEditing.stepblocks.find(stepblock => {
        return stepblock.id === stepblock_id;
      });

      let actualOrder = 0;
      if (stepblockFinded) {
        const newSteps = stepblockFinded.steps.map(step => {
          if (step.id === step_id) {
            actualOrder = step.order;
            return {
              ...step,
              order,
            };
          }
          return step;
        });

        const stepsOrdered = newSteps.map(step => {
          if (step.id !== step_id) {
            if (actualOrder < order) {
              if (step.order === order) {
                return {
                  ...step,
                  order: step.order - 1,
                };
              }
            } else if (step.order === order) {
              return {
                ...step,
                order: step.order + 1,
              };
            }
          }
          return step;
        });

        const stepblockWithNewSteps: Stepblock = {
          ...stepblockFinded,
          steps: stepsOrdered,
        };

        const newStepblocks = projectEditing.stepblocks.map(stepblock => {
          if (stepblock.id !== stepblock_id) {
            return stepblock;
          }

          return stepblockWithNewSteps;
        });

        setProjectEditing({
          ...projectEditing,
          stepblocks: newStepblocks,
        });
      }
    },
    [projectEditing],
  );

  const changeStepblockName = useCallback(
    (stepblock_id: string, name: string) => {
      const newStepblocks = projectEditing.stepblocks.map(stepblock => {
        if (stepblock.id === stepblock_id) {
          return {
            ...stepblock,
            block_name: name,
          };
        }
        return stepblock;
      });

      setProjectEditing({
        ...projectEditing,
        stepblocks: newStepblocks,
      });
    },
    [projectEditing],
  );

  const updateStepblocks = useCallback(async () => {
    const updateData = projectEditing.stepblocks.map(stepblock => {
      return {
        step_block_id: stepblock.id,
        order: stepblock.order,
        block_name: stepblock.block_name,
      };
    });

    await api.patch('/stepblocks', {
      stepblocks: updateData,
    });
  }, [projectEditing.stepblocks]);

  const updateSteps = useCallback(
    async (stepblock_id: string, stepData: StepUpdateData) => {
      const stepblockFound = projectEditing.stepblocks.find(stepblock => {
        return stepblock.id === stepblock_id;
      });

      if (stepblockFound) {
        const newSteps = stepblockFound.steps.map(step => {
          const stepFound = stepData.stepsToUpdate.find(stepFromData => {
            return stepFromData.step_id === step.id;
          });
          if (stepFound) {
            return {
              ...step,
              step_name: stepFound.step_name,
              step_description: stepFound.step_description,
              status: stepFound.status,
              order: stepFound.order,
              link_basecamp: stepFound.link_basecamp,
              link_other: stepFound.link_other,
              client: stepFound.client,
            };
          }
          return step;
        });

        const newStepblock: Stepblock = {
          ...stepblockFound,
          steps: newSteps,
        };

        const newStepblocks = projectEditing.stepblocks.map(stepblock => {
          if (stepblock.id === newStepblock.id) {
            return newStepblock;
          }
          return stepblock;
        });

        setProjectEditing({
          ...projectEditing,
          stepblocks: newStepblocks,
        });
      }

      await api.patch('/steps', {
        steps: stepData.stepsToUpdate,
      });
    },
    [projectEditing],
  );

  const removeStepblock = useCallback(
    async (stepblock_id: string) => {
      const newStepblocks = projectEditing.stepblocks.filter(stepblock => {
        return stepblock.id !== stepblock_id;
      });

      setProjectEditing({
        ...projectEditing,
        stepblocks: newStepblocks,
      });

      await api.delete(`/stepblocks/${stepblock_id}`);
    },
    [projectEditing],
  );

  const removeStep = useCallback(
    async (stepblock_id: string, step_id: string) => {
      if (projectEditing.stepblocks) {
        const stepblockFinded = projectEditing.stepblocks.find(stepblock => {
          return stepblock.id === stepblock_id;
        });
        if (stepblockFinded) {
          const newSteps = stepblockFinded.steps.filter(step => {
            return step.id !== step_id;
          });

          const stepblockWithNewSteps: Stepblock = {
            ...stepblockFinded,
            steps: newSteps,
          };

          const newStepblocks = projectEditing.stepblocks.map(stepblock => {
            if (stepblock.id !== stepblock_id) {
              return stepblock;
            }

            return stepblockWithNewSteps;
          });

          setProjectEditing({
            ...projectEditing,
            stepblocks: newStepblocks,
          });

          await api.delete(`/steps/${step_id}`);
        }
      }
    },
    [projectEditing],
  );

  const createStepJson = useCallback(
    (stepblockName: string) => {
      if (!stepblockName) {
        // se o nome não for enviado
      }

      const projectId = projectEditing.id;
      const creatorId = 'asdasdfsdf';

      let newStepjson: Stepjson = {} as Stepjson;

      if (projectEditing.stepblocks.length > 0) {
        newStepjson = {
          ...stepjson,
          stepblocks: [
            ...stepjson.stepblocks,
            {
              order: 2,
              stepblockName,
              steps: [],
            },
          ],
        };

        const response = api.patch('/stepjson', newStepjson);
      } else {
        newStepjson = {
          creatorId,
          projectId,
          createdAt: new Date(),
          updatedAt: new Date(),
          stepblocks: [
            {
              order: 1,
              stepblockName,
              steps: [],
            },
          ],
        };

        const response = api.post('/stepjson', newStepjson);
      }

      const newProjectEditing: ProjectFull2 = {
        ...projectEditing,
        stepjson: newStepjson,
      };

      setProjectEditing2(newProjectEditing);
    },
    [projectEditing, stepjson],
  );

  const createStepblock = useCallback(
    async (stepblock_name: string) => {
      let order = 1;

      if (projectEditing.stepblocks.length > 0) {
        order =
          Math.max(
            ...projectEditing.stepblocks.map(stepblock => {
              return stepblock.order;
            }),
          ) + 1;
      }

      const response = await api.post('/stepblocks', {
        block_name: stepblock_name,
        project_id: projectEditing.id,
        order,
      });

      const newStepblock: Stepblock = response.data;

      setProjectEditing({
        ...projectEditing,
        stepblocks: [...projectEditing.stepblocks, newStepblock],
      });
    },
    [projectEditing],
  );

  const createStep = useCallback(
    async (stepData: StepCreateData, stepblock_id: string) => {
      const stepblockFound = projectEditing.stepblocks.find(stepblock => {
        return stepblock.id === stepblock_id;
      });

      if (stepblockFound) {
        let order = 1;
        if (stepblockFound.steps) {
          order =
            stepblockFound.steps.length > 0
              ? Math.max(
                  ...stepblockFound.steps.map(step => {
                    return step.order;
                  }),
                ) + 1
              : 1;
        }

        const stepToPost = {
          step_name: stepData.step_name,
          step_description: stepData.step_description,
          link_basecamp: stepData.link_basecamp,
          link_other: stepData.link_other,
          status: stepData.status,
          order,
          client: stepData.client,
          stepblock_id,
        };

        const response = await api.post('/steps', stepToPost);

        let newStepblock: Stepblock = {} as Stepblock;
        if (stepblockFound.steps) {
          newStepblock = {
            ...stepblockFound,
            steps: [...stepblockFound.steps, response.data],
          };
        } else {
          newStepblock = {
            ...stepblockFound,
            steps: [response.data],
          };
        }

        const stepblocks = projectEditing.stepblocks.map(stepblock => {
          if (stepblock.id === newStepblock.id) {
            return newStepblock;
          }
          return stepblock;
        });

        setProjectEditing({
          ...projectEditing,
          stepblocks,
        });
      }
    },
    [projectEditing],
  );

  const saveProject = useCallback(
    async (project_name: string, company_name: string) => {
      const newProject = {
        ...projectEditing,
        company_name,
        project_name,
      };

      setProjectEditing(newProject);

      const projects = data.projects.map(project => {
        if (project.id === projectEditing.id) {
          return newProject;
        }
        return project;
      });

      setData({ projects });

      await api.put(`/projects/${projectEditing.id}`, {
        project_name,
        company_name,
      });
    },
    [data.projects, projectEditing],
  );

  return (
    <ProjectContext.Provider
      value={{
        projects: data.projects,
        setProjectForEdit,
        projectEditing,
        clearProjectForEdit,
        changeStepblockOrder,
        changeStepblockName,
        updateStepblocks,
        removeStepblock,
        createStepblock,
        changeStepOrder,
        updateSteps,
        removeStep,
        createStep,
        saveProject,
        createProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export function useProject(): ProjectContextData {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error('useProject must be used within an ProjectProvider');
  }

  return context;
}
