import styled from 'styled-components';

interface ContainerProps {
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  input {
    display: flex;
    flex: 1;
    border-radius: 8px;
    padding: 10px 10px;
    border: 1px solid
      ${props => (props.isErrored ? 'var(--red)' : 'var(--gray-light)')};
    margin-top: 10px;
    transition: all 200ms;
  }

  label {
    position: absolute;
    top: 20px;
    left: 0;
    margin-left: 10px;
    font-size: 16px;
    transition: all 200ms;
    padding: 0px 5px 0px 5px;
    color: ${props => (props.isErrored ? 'var(--red)' : 'var(--gray-light)')};
    cursor: text;
  }

  &:not(:first-child) {
    margin-top: 7px;
  }

  input:focus {
    border-color: var(--green-text);
  }

  input:focus + label,
  .not-empty {
    top: 0px;
    font-size: 14px;
    left: 0px;
    background-color: white;
    color: var(--green-text);
  }

  .not-empty {
    color: ${props => (props.isErrored ? 'var(--red)' : 'var(--gray-light)')};
  }
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 2px;
  text-align: right;
`;
