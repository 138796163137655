import {
  faCheckCircle,
  faChevronDown,
  faCommentDots,
  faExternalLinkAlt,
  faHourglassHalf,
  faInfoCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ptBR from 'date-fns/locale/pt-BR';
import Badge from '../../components/Badge';
import ContainerGlobal from '../../components/ContainerGlobal';
import DashboardContainer from '../../components/DashboardContainer';
import DashboardTitle from '../../components/DashboardTitle';
import DashboardTopMenu from '../../components/DashboardTopMenu';
import Menu from '../../components/Menu';
import StatusCircle from '../../components/StatusCircle';
import { Step } from '../../entities/ProjectFull';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/Modal';
import { ProjectState, useUserProjects } from '../../hooks/userProjects';
import ClientMenuObjects from '../../objects/clientMenu';
import { LoadingIcon } from '../../styles/global';
import {
  WidgetProject,
  Widget,
  WidgetContainer,
  WidgetTitle,
  ProjectStatus,
  StepblocksContainerTitle,
  StepblocksContainer,
  StepContainer,
} from './styles';

const Project: React.FC = () => {
  const { user } = useAuth();
  const { showModal } = useModal();
  const history = useHistory();

  if (user.admin) {
    history.push('/admin/dashboard');
  }

  const { projects } = useUserProjects();
  const [projectActive, setProjectActive] = useState({} as ProjectState);
  const [stuckStep, setStuckStep] = useState<Step | undefined>({} as Step);
  const [ongoingStep, setOngoingStep] = useState<Step | undefined>({} as Step);
  const [status, setStatus] = useState('');
  const [statusText, setStatusText] = useState('');

  useEffect(() => {
    const project_id = localStorage.getItem('@Supersonic:project');

    const prjc = projects.find(prj => {
      return prj.project.id === project_id;
    });

    if (prjc) {
      setProjectActive(prjc);
      return;
    }
  }, [history, projects]);

  useEffect(() => {
    if (!projectActive.project) return;

    let stepStuck: Step | undefined;
    projectActive.project.stepblocks.forEach(stepblock => {
      if (stepStuck) {
        return stepStuck;
      }

      stepStuck = stepblock.steps.find(step => {
        return step.status === 'stuck';
      });

      return stepStuck;
    });

    setStuckStep(stepStuck);

    let stepOngoing: Step | undefined;
    projectActive.project.stepblocks.forEach(stepblock => {
      if (stepOngoing) {
        return stepOngoing;
      }

      stepOngoing = stepblock.steps.find(step => {
        return step.status === 'ongoing';
      });

      return stepOngoing;
    });

    setOngoingStep(stepOngoing);

    let stepStandby: Step | undefined;
    projectActive.project.stepblocks.forEach(stepblock => {
      stepStandby = stepblock.steps.find(step => {
        return step.status === 'standby';
      });

      return stepStandby;
    });

    if (stepStandby && !stepOngoing && !stepStuck) {
      setStatus('standby');
      setStatusText('Aguardando');
    }

    if (stepOngoing || stepStuck) {
      setStatus('ongoing');
      setStatusText('Em andamento');
    }

    if (!stepOngoing && !stepStuck && !stepStandby) {
      setStatus('finished');
      setStatusText('Finalizado');
    }
  }, [projectActive]);

  const showStepContent = useCallback(
    (event, step: Step) => {
      event.preventDefault();
      showModal({
        title: 'Detalhamento de etapa',
        content: (
          <>
            <p>
              <strong>{`Etapa: `}</strong>
              {step.step_name}
            </p>
            <br />
            <p>
              <strong>{`Descrição: `}</strong>
              {step.step_description}
            </p>
            <br />
            <p>
              <strong>{`Responsável por finalizar: `}</strong>
              {step.client ? projectActive.project.company_name : 'Supersonic'}
            </p>
            {step.link_basecamp && (
              <>
                <br />
                <p>
                  <strong>{`Link Basecamp: `}</strong>
                  <a href={step.link_basecamp} target="_blank" rel="noreferrer">
                    {step.link_basecamp}
                  </a>
                </p>
              </>
            )}
            {step.link_other && (
              <>
                <br />
                <p>
                  <strong>{`Link externo: `}</strong>
                  <a href={step.link_other} target="_blank" rel="noreferrer">
                    {step.link_other}
                  </a>
                </p>
              </>
            )}
            <br />
            <p>
              <span style={{ fontSize: '12px' }}>
                {`Última atualização ${format(
                  parseISO(step.updated_at),
                  "d 'de' MMMM 'de' yyyy",
                  { locale: ptBR },
                )}`}
              </span>
            </p>
          </>
        ),
      });
    },
    [projectActive.project, showModal],
  );

  return (
    <>
      <ContainerGlobal>
        <Menu items={ClientMenuObjects.itemsProject} />
        <DashboardContainer>
          <DashboardTopMenu />
          {!projectActive.project && (
            <LoadingIcon>
              <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
            </LoadingIcon>
          )}
          {projectActive.project && (
            <>
              <WidgetContainer>
                <Widget>
                  <WidgetTitle className="project-title">{`${projectActive.project.company_name} | ${projectActive.project.project_name}`}</WidgetTitle>
                  <ProjectStatus status={status}>
                    Status:&nbsp;
                    <strong>{statusText}</strong>
                  </ProjectStatus>
                  <p>
                    <strong>
                      Seja bem vindo(a) à plataforma de acompanhamento do seu
                      projeto!
                    </strong>
                  </p>
                  <p>
                    Aqui sua equipe pode verificar o status de cada etapa, se
                    atualizar sobre o andamento geral do projeto e ser informado
                    sobre os retornos que aguardamos de vocês.
                  </p>
                </Widget>
              </WidgetContainer>
              <WidgetContainer>
                <WidgetProject className="doing">
                  <h3>O que nós estamos fazendo</h3>
                  {ongoingStep && <p>{ongoingStep.step_name}</p>}
                  {!ongoingStep && <p>Não existem etapas em andamento.</p>}
                </WidgetProject>
                <WidgetProject className="need">
                  <h3>O que precisamos de você</h3>
                  {stuckStep && <p>{stuckStep.step_name}</p>}
                  {!stuckStep && <p>Não precisamos de nada no momento!</p>}
                </WidgetProject>
              </WidgetContainer>
              <DashboardTitle>Detalhamento do projeto</DashboardTitle>
              <StepblocksContainerTitle>
                <div>ETAPA</div>
                <div className="desktop">RESPONSÁVEL</div>
                <div className="desktop">DESCRIÇÃO / LINKS</div>
                <div className="mobile">LINKS</div>
              </StepblocksContainerTitle>
              {projectActive.project.stepblocks.map(stepblock => {
                let stepStatus = stepblock.steps.find(step => {
                  return step.status === 'stuck' || step.status === 'ongoing';
                })?.status;

                let stepblockStatus;
                if (stepStatus === 'stuck') {
                  stepblockStatus = 'Aguardando cliente';
                } else {
                  stepblockStatus = 'Etapa em andamento';
                }

                if (stepStatus !== 'ongoing' && stepStatus !== 'stuck') {
                  const stepStandby = stepblock.steps.find(step => {
                    return step.status === 'standby';
                  })?.status;

                  if (stepStandby) {
                    stepStatus = 'standby';
                    stepblockStatus = 'Aguardando';
                  } else {
                    stepStatus = 'finished';
                    stepblockStatus = 'Etapa finalizada';
                  }
                }

                const stepblockNum = String(stepblock.order).padStart(2, '0');
                return (
                  <StepblocksContainer
                    htmlFor={stepblock.id}
                    status={stepStatus}
                    key={stepblock.id}
                  >
                    <input type="checkbox" hidden id={stepblock.id} />
                    <div className="stepblocks-line">
                      <div>
                        <FontAwesomeIcon icon={faChevronDown} fixedWidth />
                        {`${stepblockNum}. ${stepblock.block_name}`}
                      </div>
                      <div className="stepblock-status">
                        {stepStatus !== 'standby' && (
                          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                        )}
                        {stepStatus === 'standby' && (
                          <FontAwesomeIcon
                            className="standby-icon"
                            icon={faHourglassHalf}
                            fixedWidth
                          />
                        )}
                        <span className="desktop">{`${stepblockStatus}`}</span>
                      </div>
                      <div className="stepblock-last-div" />
                    </div>
                    <div className="steps-toggle">
                      {stepblock.steps.map(step => {
                        return (
                          <StepContainer key={step.id} status={step.status}>
                            <div className="step-status">
                              {step.status === 'finished' && (
                                <>
                                  <FontAwesomeIcon
                                    className="desktop"
                                    icon={faCheckCircle}
                                    fixedWidth
                                  />
                                  <StatusCircle color="green" />
                                </>
                              )}
                              {(step.status === 'stuck' ||
                                step.status === 'ongoing') && (
                                <>
                                  <FontAwesomeIcon
                                    className="desktop"
                                    icon={faCheckCircle}
                                    fixedWidth
                                  />
                                  <StatusCircle color="yellow" />
                                </>
                              )}
                              {step.status === 'standby' && (
                                <>
                                  <FontAwesomeIcon
                                    className="standby-icon desktop"
                                    icon={faHourglassHalf}
                                    fixedWidth
                                  />
                                  <StatusCircle color="gray" />
                                </>
                              )}
                              {step.client && step.status === 'stuck' && (
                                <Badge color="yellow" className="mobile">
                                  Aguardando o cliente
                                </Badge>
                              )}
                              <div className="step-name">{step.step_name}</div>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="step-info-mobile"
                                onClick={event => showStepContent(event, step)}
                                fixedWidth
                              />
                            </div>
                            <div className="step-responsible desktop">
                              {step.client
                                ? projectActive.project.company_name
                                : 'Supersonic'}
                            </div>
                            <div className="step-description">
                              {step.step_description}
                            </div>
                            {step.link_basecamp && (
                              <div className="step-links">
                                {step.link_basecamp && (
                                  <a
                                    href={step.link_basecamp}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Link para o Basecamp"
                                  >
                                    <FontAwesomeIcon
                                      icon={faCommentDots}
                                      fixedWidth
                                    />
                                  </a>
                                )}
                                {step.link_other && (
                                  <a
                                    href={step.link_other}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Outros links"
                                  >
                                    <FontAwesomeIcon
                                      icon={faExternalLinkAlt}
                                      fixedWidth
                                    />
                                  </a>
                                )}
                              </div>
                            )}
                          </StepContainer>
                        );
                      })}
                    </div>
                  </StepblocksContainer>
                );
              })}
            </>
          )}
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default Project;
