import React from 'react';
import {
  Route as ReactRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  isClient?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  isClient = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  return (
    <ReactRoute
      {...rest}
      render={({ location }) => {
        if (user !== undefined && isPrivate === !!user) {
          if (isAdmin === user.admin) {
            return <Component />;
          }
          if (isClient === user.client) {
            return <Component />;
          }
          if (isAdmin !== user.admin) {
            return (
              <Redirect
                to={{
                  pathname: '/admin/dashboard',
                  state: { from: location },
                }}
              />
            );
          }
          if (isClient !== user.client) {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: location },
                }}
              />
            );
          }
        }
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/login' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
