import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;

  h1 {
    font-size: 22px;
    border-bottom: 1px solid var(--black);
    padding-bottom: 5px;
  }

  > p {
    margin-top: 15px;
  }
`;

export default Container;
