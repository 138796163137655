import styled from 'styled-components';

import { BadgeProps } from './index';

const Container = styled.section<BadgeProps>`
  margin: 0 5px;
  font-size: 12px;
  color: var(--gray-blue);
  position: relative;
  text-align: center;
  border-radius: 5px;
  padding: 4px 4px;
  ${props => {
    if (props.color === 'green') {
      return 'background-color: #7EE14A;';
    }
    if (props.color === 'yellow') {
      return 'background-color: #FFCF6F;';
    }

    return 'background-color: #2B343D;';
  }};
`;

export default Container;
