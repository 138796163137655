import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import { ProjectProvider } from '../hooks/projects';
import { UsersProvider } from '../hooks/users';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import AdminUsers from '../pages/Admin/Users';
import AdminProject from '../pages/Admin/Project';
import AdminDashboard from '../pages/Admin/Dashboard';
import AdminUser from '../pages/Admin/User';
import AdminProjectTests from '../pages/Admin/ProjectTests';
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Project from '../pages/Project';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/change-password" isPrivate component={ChangePassword} />

    <Route path="/dashboard" exact isPrivate isClient component={Dashboard} />
    <Route path="/project" exact isPrivate isClient component={Project} />
    <Route path="/results" exact isPrivate isClient component={Project} />

    <ProjectProvider>
      <UsersProvider>
        <Route
          path="/admin/dashboard"
          isPrivate
          isAdmin
          component={AdminDashboard}
        />
        <Route path="/admin/users" isPrivate isAdmin component={AdminUsers} />
        <Route path="/admin/user" isPrivate isAdmin component={AdminUser} />
        <Route
          path="/admin/project"
          isPrivate
          exact
          isAdmin
          component={AdminProject}
        />
        <Route
          path="/admin/project/tests"
          isPrivate
          isAdmin
          component={AdminProjectTests}
        />
      </UsersProvider>
    </ProjectProvider>
  </Switch>
);

export default Routes;
