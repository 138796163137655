import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 500px;

  .project {
    padding: 15px;
    background-color: white;
    border-radius: 15px;
    margin: 5px 0;
    display: flex;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    transition: all 200ms;
    border: none;
    text-align: left;

    &:hover {
      background-color: var(--green2);
      cursor: pointer;
      color: white;
    }

    div {
      flex: 1;
      padding-right: 10px;
    }
  }
`;

export const ListHeadings = styled.section`
  display: flex;
  padding: 5px 15px 10px 15px;
  div {
    flex: 1;
    color: var(--black);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const PaginationContainer = styled.section`
  display: flex;
`;

export const SearchContainer = styled.section`
  margin: 10px 0px 20px 0px;
  display: flex;
  align-items: center;

  div {
    position: relative;
  }

  input[type='text'] {
    padding: 10px 40px 10px 10px;
    border: none;
    border-radius: 4px;
    width: 275px;
  }

  .search-checkbox {
    background-color: white;
    padding: 10px;
    margin-left: 15px;
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
`;
