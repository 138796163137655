/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Project from '../../entities/Project';

import {
  Container,
  PaginationContainer,
  ListHeadings,
  SearchContainer,
} from './styles';
import { LoadingIcon } from '../../styles/global';
import { useProject } from '../../hooks/projects';

interface PaginationProps {
  items: Project[];
  itemsPerPage: number;
}

const AdminDashProjectListPaginated: React.FC<PaginationProps> = ({
  items,
  itemsPerPage,
}: PaginationProps) => {
  const [length, setLength] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const searchRef = useRef<HTMLInputElement>(null);
  const ongoingRef = useRef<HTMLInputElement>(null);
  const finishedRef = useRef<HTMLInputElement>(null);

  const [filterBy, setFilterBy] = useState('');

  const { clearProjectForEdit } = useProject();
  const history = useHistory();

  const updateFilter = useCallback(
    (type: string) => {
      if (filterBy !== type) {
        setFilterBy(type);
      } else {
        setFilterBy('');
      }

      if (type === 'finished') {
        ongoingRef.current!.checked = false;
      } else {
        finishedRef.current!.checked = false;
      }
    },
    [filterBy],
  );

  useEffect(() => {
    setLength(items.length);
  }, [items]);

  useEffect(() => {
    const newLength = items
      .filter(item => {
        if (filterBy.length > 0) {
          return item.status === filterBy;
        }
        return item;
      })
      .filter(item => {
        if (searchTerm.length > 0) {
          return (
            item.project_name
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1 ||
            item.company_name
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
          );
        }
        return item;
      }).length;
    setLength(newLength);
    setActualPage(1);
  }, [items, searchTerm, filterBy]);

  const pages = useMemo(() => {
    return Math.ceil(length / itemsPerPage);
  }, [itemsPerPage, length]);

  const changePage = useCallback((i: number) => {
    setActualPage(i);
  }, []);

  const prevPage = useCallback(() => {
    setActualPage(actualPage - 1);
  }, [actualPage]);

  const nextPage = useCallback(() => {
    setActualPage(actualPage + 1);
  }, [actualPage]);

  const pagination = useMemo(() => {
    const paginationElements: React.ReactNode[] = [];

    paginationElements.push(
      <button
        key="prevPage"
        className="prevButton"
        type="button"
        onClick={() => prevPage()}
        disabled={actualPage <= 1}
      >
        &lt;
      </button>,
    );
    for (let i = 0; i < pages; i += 1) {
      paginationElements.push(
        <button
          key={`page-${i}`}
          className={`pageButton ${
            actualPage === i + 1 ? 'navButtonSelected' : ''
          }`}
          type="button"
          onClick={() => changePage(i + 1)}
        >
          {`${i + 1}`}
        </button>,
      );
    }
    paginationElements.push(
      <button
        key="nextPage"
        className="nextButton"
        type="button"
        onClick={() => nextPage()}
        disabled={actualPage >= pages}
      >
        &gt;
      </button>,
    );
    return paginationElements;
  }, [actualPage, changePage, nextPage, pages, prevPage]);

  const setProjectForEditing = useCallback(
    (project_id: string) => {
      clearProjectForEdit();
      history.push(`/admin/project?project_id=${project_id}`);
    },
    [clearProjectForEdit, history],
  );

  const renderItems = useMemo(() => {
    return items
      .filter(item => {
        if (filterBy.length > 0) {
          return item.status === filterBy;
        }
        return item;
      })
      .filter(item => {
        if (searchTerm.length > 0) {
          return (
            item.project_name
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1 ||
            item.company_name
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
          );
        }
        return item;
      })
      .filter((item, index) => {
        return (
          index >= (actualPage - 1) * itemsPerPage &&
          index <= actualPage * itemsPerPage - 1
        );
      })
      .map(item => {
        return (
          <button
            type="button"
            className="project"
            key={item.id}
            onClick={() => setProjectForEditing(item.id)}
          >
            <div>{item.project_name}</div>
            <div>{item.company_name}</div>
            <div>
              {item.status === 'ongoing' ? 'Em andamento' : 'Finalizado'}
            </div>
            <div>
              {format(new Date(item.created_at), `dd'/'MM'/'yyyy`, {
                locale: ptBR,
              })}
            </div>
          </button>
        );
      });
  }, [
    actualPage,
    filterBy,
    items,
    itemsPerPage,
    searchTerm,
    setProjectForEditing,
  ]);

  const handleSearch = useCallback(() => {
    if (searchRef.current) {
      setSearchTerm(searchRef.current.value);
    }
  }, []);

  return (
    <Container>
      <SearchContainer>
        <div>
          <input
            ref={searchRef}
            type="text"
            name="search"
            id="search"
            onChange={handleSearch}
            placeholder="Pesquisar por nome"
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className="search-checkbox">
          <input
            type="checkbox"
            name="ongoing"
            id="ongoing"
            ref={ongoingRef}
            onChange={() => updateFilter('ongoing')}
          />
          <label htmlFor="ongoing">Em andamento</label>
        </div>
        <div className="search-checkbox">
          <input
            type="checkbox"
            name="finished"
            id="finished"
            ref={finishedRef}
            onChange={() => updateFilter('finished')}
          />
          <label htmlFor="finished">Finalizados</label>
        </div>
      </SearchContainer>
      <ListHeadings>
        <div>Nome do projeto</div>
        <div>Nome da empresa</div>
        <div>Status</div>
        <div>Data de criação</div>
      </ListHeadings>
      {items.length === 0 && (
        <LoadingIcon>
          <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
        </LoadingIcon>
      )}
      {renderItems}
      <PaginationContainer className="pagination-container">
        {pagination}
      </PaginationContainer>
    </Container>
  );
};

export default AdminDashProjectListPaginated;
