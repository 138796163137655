import styled from 'styled-components';

import scientist from '../../assets/images/scientist.svg';
import tasks from '../../assets/images/tasks-note.svg';

interface StepStatus {
  status?: string;
}

export const WidgetContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .green1 {
    background-color: var(--green1);
    color: white;
  }
  .green2 {
    background-color: var(--green2);
    color: white;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Widget = styled.section`
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  &:last-of-type {
    margin-right: 0px;
  }

  p {
    margin-top: 15px;
  }
`;

export const ProjectStatus = styled.span<StepStatus>`
  width: fit-content;
  padding: 5px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 8px;
  margin-top: 8px;
  letter-spacing: 1px;
  background-color: ${props => {
    if (props.status === 'finished') {
      return 'var(--green2)';
    }
    if (props.status === 'standby') {
      return 'var(--gray-lighter)';
    }
    return 'var(--yellow)';
  }};
  color: ${props => (props.status === 'finished' ? 'white' : 'var(--black)')};
  position: absolute;
  top: 10px;
  right: 20px;
`;

export const WidgetTitle = styled.h3`
  font-size: 28px;
  color: var(--black);
  margin-bottom: 5px;

  &.project-title {
    @media (max-width: 1023px) {
      margin-top: 40px;
    }
  }
`;

export const WidgetNumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    max-width: 100px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const WidgetNumber = styled.h3`
  font-weight: 700;
  font-size: 68px;
  margin: 0 15px 0 0;
`;

export const AccessProjectButton = styled.button`
  padding: 15px;
  margin: 10px 0;
  background-color: var(--green2);
  border: none;
  text-align: left;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  margin-bottom: 0;
`;

export const WidgetProject = styled.section`
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  @media (max-width: 1023px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  &.doing {
    background-color: var(--green2);
    background-image: url(${scientist});
    background-repeat: no-repeat;
    background-position: 98% 10px;
    background-size: auto 180%;

    @media (max-width: 1130px) {
      h3,
      p {
        max-width: 60%;
      }
    }

    @media (max-width: 1023px) {
      h3,
      p {
        max-width: 70%;
      }
    }

    @media (max-width: 768px) {
      h3,
      p {
        max-width: 50%;
      }
    }
  }

  &.need {
    background-color: var(--black);
    background-image: url(${tasks});
    background-repeat: no-repeat;
    background-position: 110% 10px;
    background-size: auto 80%;
    color: white;
    margin-right: 0px;

    @media (max-width: 1190px) {
      background-position: right -60% top 17px;
    }

    @media (max-width: 1130px) {
      h3,
      p {
        max-width: 60%;
      }
    }
    @media (max-width: 1023px) {
      background-position: right -4% top 17px;
      h3,
      p {
        max-width: 70%;
      }
    }
    @media (max-width: 768px) {
      h3,
      p {
        max-width: 50%;
      }
    }
    @media (max-width: 767px) {
      background-position: right -75% top 17px;
    }
  }

  h3 {
    font-size: 18px;
    max-width: 45%;
    font-weight: 600;
  }
  p {
    margin: 20px 0;
    max-width: 45%;
    font-size: 16px;
  }
`;

export const StepblocksContainerTitle = styled.div`
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--black);
  font-weight: 700;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 15px;

  div {
    flex: 1;
  }

  div:first-child {
    max-width: 230px;
    @media (max-width: 1024px) {
      max-width: 180px;
    }
  }

  @media (max-width: 767px) {
    padding-left: 10px;
    justify-content: space-between;

    > div {
      max-width: none !important;
      flex: none;
      padding-right: 10px;
    }
  }
`;

export const StepblocksContainer = styled.label<StepStatus>`
  display: flex;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: 15px 0;
  color: var(--black);
  font-weight: 700;
  font-size: 18px;
  flex-wrap: wrap;
  cursor: pointer;

  .stepblocks-line {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 25px 30px;

    @media (max-width: 1024px) {
      padding: 25px 20px;
    }

    @media (max-width: 767px) {
      padding: 15px;
      font-size: 14px;

      > div:first-child svg {
        font-size: 12px;
      }
    }
  }

  .stepblocks-line .stepblock-status {
    padding-left: 30px;
    min-width: 230px;

    @media (max-width: 767px) {
      min-width: auto;
      padding-left: 10px;
    }
  }

  .stepblocks-line div:first-child {
    flex: 1;
    svg {
      margin-right: 10px;
      transition: transform 200ms;
    }
  }

  .stepblock-status {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: ${props => {
      if (props.status === 'finished') {
        return 'var(--green2)';
      }
      if (props.status === 'standby') {
        return 'var(--green-lighter)';
      }
      return 'var(--yellow)';
    }};

    svg {
      font-size: 24px;
      margin-right: 10px;
      &.standby-icon {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 22px;
        margin-right: 5px;
      }
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .steps-toggle {
    width: 100%;
    display: none;
  }

  input:checked ~ .steps-toggle {
    display: block;
  }
  input:checked ~ .stepblocks-line div:first-child svg {
    transform: rotate(-180deg);
  }
`;

export const StepContainer = styled.div<StepStatus>`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: normal;
  background-color: #eaeaea30;
  color: var(--gray-blue);

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px;

    @media (max-width: 767px) {
      border-right: none;
    }
  }

  .step-description {
    flex: 1;
    width: 100%;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .step-responsible {
    flex: auto;
    max-width: 150px;
    @media (max-width: 1023px) {
      flex: 1;
      max-width: none;
      border-right: none;
    }
  }

  .step-name {
    flex: 1;
  }

  @media (max-width: 1023px) {
    .step-name {
      flex: none;
    }
  }

  .step-links {
    flex: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: none;
    padding: 0 15px;
    a {
      color: var(--black);
      transition: all 200ms;
    }
    a:hover {
      opacity: 0.5;
    }

    svg {
      margin: 0 5px;
    }

    @media (max-width: 1023px) {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .step-status {
    display: flex;
    align-items: center;
    max-width: 250px;
    padding-left: 30px;
    font-weight: 600;

    @media (max-width: 1024px) {
      min-width: 250px;
      padding-left: 20px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      padding-left: 10px;
      flex-wrap: wrap;

      .badge {
        margin-bottom: 8px;
        margin-left: 0px;
      }
    }

    svg {
      margin-right: 8px;
      font-size: 18px;
      color: ${props => {
        if (props.status === 'finished') {
          return 'var(--green2)';
        }
        if (props.status === 'standby') {
          return 'var(--green-lighter)';
        }
        return 'var(--yellow)';
      }};
      &.standby-icon {
        font-size: 14px;
      }
    }

    .step-info-mobile {
      color: var(--gray-blue);
      font-size: 14px;
      margin-left: 5px;
    }
  }
`;
