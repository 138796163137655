import React from 'react';
import { useAuth } from '../../../hooks/auth';
import AdminDashboardComponent from './admin';

const AdminDashboard: React.FC = () => {
  const { user } = useAuth();

  return <>{user.admin && <AdminDashboardComponent />}</>;
};

export default AdminDashboard;
