import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ContainerGlobal from '../../components/ContainerGlobal';
import DashboardContainer from '../../components/DashboardContainer';
import DashboardTopMenu from '../../components/DashboardTopMenu';
import Menu from '../../components/Menu';
import { useAuth } from '../../hooks/auth';
import {
  UserProjectsProvider,
  useUserProjects,
} from '../../hooks/userProjects';
import ClientMenuObjects from '../../objects/clientMenu';
import { LoadingIcon } from '../../styles/global';
import {
  AccessProjectButton,
  Widget,
  WidgetContainer,
  WidgetTitle,
} from './styles';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  if (user.admin) {
    history.push('/admin/dashboard');
  }

  const { projects } = useUserProjects();

  function handleProjectAccess(project_id: string) {
    localStorage.setItem(`@Supersonic:project`, project_id);
    history.push(`/project`);
  }

  return (
    <>
      <UserProjectsProvider>
        <ContainerGlobal>
          <Menu items={ClientMenuObjects.items} />
          <DashboardContainer>
            <DashboardTopMenu />
            <WidgetContainer>
              <Widget>
                <WidgetTitle>{`Olá ${user.name.split(' ')[0]}!`}</WidgetTitle>
                <p>
                  <strong>
                    Seja bem vindo(a) à plataforma de acompanhamento do seu
                    projeto!
                  </strong>
                </p>
                <p>
                  Aqui sua equipe pode verificar o status de cada etapa, se
                  atualizar sobre o andamento geral do projeto e ser informado
                  sobre os retornos que aguardamos de vocês.
                </p>
              </Widget>
            </WidgetContainer>
            <WidgetContainer>
              <Widget>
                <WidgetTitle>Selecione o projeto</WidgetTitle>
                {projects.length === 0 && (
                  <LoadingIcon>
                    <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                  </LoadingIcon>
                )}
                {projects &&
                  projects.map(project => {
                    return (
                      <AccessProjectButton
                        className="btn-primary"
                        key={project.id}
                        onClick={() => handleProjectAccess(project.project.id)}
                      >
                        {`${project.project.company_name} | ${project.project.project_name}`}
                      </AccessProjectButton>
                    );
                  })}
              </Widget>
            </WidgetContainer>
          </DashboardContainer>
        </ContainerGlobal>
      </UserProjectsProvider>
    </>
  );
};

export default Dashboard;
