import React, { useEffect, useMemo, useState } from 'react';
import { ChartData } from 'chart.js';
import Button from '../../../components/Button';
import Whitebox from '../../../components/Whitebox';
import api from '../../../services/api';
import { ErrorBox } from '../../../styles/global';

import TableContainer from './styles';
import ChartLine from '../../../components/ChartLine';

interface MetricData {
  columnHeader: {
    dimensions: string[];
    metricHeader: {
      metricHeaderEntries: {
        name: string;
        type: string;
      }[];
    };
  };
  data: {
    rows: {
      dimensions: string[];
      metrics: {
        values: string[];
      }[];
    }[];
    totals: {
      values: string[];
    }[];
    rowCount: number;
    minimums: {
      values: string[];
    };
    maximums: {
      values: string[];
    };
  };
}

export interface AnalyticsData {
  metrics: MetricData[];
}

interface TestDataViewProps {
  experiment_id?: string;
}

const TestDataView: React.FC<TestDataViewProps> = ({ experiment_id }) => {
  const [gaData, setGaData] = useState<AnalyticsData>({
    metrics: [],
  });

  const [chartData, setChartData] = useState<ChartData>({} as ChartData);

  const [gaError, setGaError] = useState('');

  useEffect(() => {
    const dataStored = localStorage.getItem(
      `@Supersonic:test:data:${experiment_id}`,
    );

    if (dataStored) {
      setGaData({
        metrics: JSON.parse(dataStored),
      });
    }
  }, [experiment_id]);

  async function loadAnalyticsData() {
    if (experiment_id) {
      setGaError('');
      api
        .get(`/analytics/test-data/${experiment_id}`)
        .then(response => {
          setGaData({
            metrics: JSON.parse(response.data.data),
          });

          localStorage.setItem(
            `@Supersonic:test:data:${experiment_id}`,
            response.data.data,
          );
        })
        .catch(error => {
          console.log(error);
          setGaError(`Error: ${error.message}`);
        });
    }
  }

  const renderGaData = useMemo(() => {
    if (gaData.metrics && gaData.metrics.length > 0) {
      const metricTables = gaData.metrics[0].columnHeader.metricHeader.metricHeaderEntries.map(
        (gaMetric, index) => {
          return (
            <TableContainer
              key={
                gaData.metrics[0].columnHeader.metricHeader.metricHeaderEntries[
                  index
                ].name
              }
            >
              <thead>
                <tr>
                  <th>Métrica:</th>
                  <th>
                    {
                      gaData.metrics[0].columnHeader.metricHeader
                        .metricHeaderEntries[index].name
                    }
                  </th>
                </tr>
                <tr>
                  <th>Total:</th>
                  <th>{gaData.metrics[0].data.totals[0].values[index]}</th>
                </tr>
                <tr>
                  <th>Data</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {gaData.metrics[0].data.rows.map(row => {
                  const date = `${row.dimensions[0].substring(
                    6,
                    8,
                  )}/${row.dimensions[0].substring(
                    4,
                    6,
                  )}/${row.dimensions[0].substring(0, 4)}`;
                  return (
                    <tr key={date}>
                      <td>{date}</td>
                      <td>{row.metrics[0].values[index]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </TableContainer>
          );
        },
      );
      return metricTables;
    }
    return <p>Nenhum dado foi encontrado.</p>;
  }, [gaData.metrics]);

  useEffect(() => {
    if (gaData && gaData.metrics.length > 0) {
      const chart: ChartData = {
        labels: gaData.metrics[0].data.rows.map(row => {
          const date = `${row.dimensions[0].substring(
            6,
            8,
          )}/${row.dimensions[0].substring(4, 6)}/${row.dimensions[0].substring(
            0,
            4,
          )}`;
          return date;
        }),
        datasets: gaData.metrics[0].columnHeader.metricHeader.metricHeaderEntries.map(
          (metric, index) => {
            return {
              data: gaData.metrics[0].data.rows.map(row => {
                return Number(row.metrics[0].values[index]);
              }),
              label: metric.name,
            };
          },
        ),
      };
      console.log(chart);

      setChartData(chart);
    }
  }, [gaData]);

  console.log(gaData);

  return (
    <>
      <Whitebox>
        <h1>Dados do Google Analytics</h1>
        {gaError.length > 0 && <ErrorBox>{gaError}</ErrorBox>}
        {gaData.metrics.length === 0 && <p>Nenhum dado foi encontrado.</p>}
        {gaData.metrics.length > 0 && renderGaData}
        <Button type="button" onClick={loadAnalyticsData}>
          Carregar dados do GA
        </Button>
      </Whitebox>
      <Whitebox>
        <h1>Gráficos</h1>
        {chartData.datasets && <ChartLine chartData={chartData} />}
      </Whitebox>
    </>
  );
};

export default TestDataView;
