import styled from 'styled-components';

const Container = styled.section`
  padding: 40px;
  display: flex;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export default Container;
