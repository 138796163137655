import styled from 'styled-components';

export const EditingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  button {
    max-width: 340px;
  }
`;

export const Stepblocks = styled.section`
  position: relative;
  margin-top: 15px;

  svg {
    cursor: pointer;
    transition: all 200ms;
  }
  svg:hover {
    opacity: 0.5;
  }
`;

export const Stepblock = styled.section`
  position: relative;
  margin: 10px 0;
  padding: 10px 15px;
  background-color: #f3f3f3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StepMoveable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    font-size: 18px;
  }
  .chevron-disabled {
    opacity: 0.2;
  }
  .chevron-disabled:hover {
    opacity: 0.2;
  }
`;

export const StepName = styled.div`
  display: flex;
  flex: 1;

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--gray);
    width: 100%;
  }
`;

export const StepOptions = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CreateStepblockContainer = styled.div`
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid var(--gray-light);
`;

export const CreateStepblock = styled.div`
  position: relative;
  margin: 10px 0;
  padding: 10px 5px 10px 15px;
  background-color: #f3f3f3;
  border-radius: 10px;
  display: flex;

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--gray);
    width: 100%;
    flex: 1;
  }
  button {
    width: auto;
  }
`;

export const CreateStepblockError = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 15px;
  background-color: var(--red-light);
  color: var(--red);
  border: 1px solid var(--red);
`;
