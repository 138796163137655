import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  label?: string;
  isChecked?: boolean;
}

const InputCheckbox: React.FC<InputProps> = ({
  name,
  id,
  containerStyle = {},
  label = '',
  isChecked = false,
  ...rest
}) => {
  const [checked, setChecked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  function handleChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setChecked(!!e.target.checked);
  }

  return (
    <Container style={containerStyle} isErrored={!!error}>
      <input
        type="checkbox"
        name={name}
        id={id}
        ref={inputRef}
        checked={checked}
        onChange={handleChanged}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputCheckbox;
