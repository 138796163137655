import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
  faEllipsisV,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  MenuContainer,
  MenuFixedWrapper,
  MenuItem,
  MenuMobileOpen,
  MenuMobileOptions,
  MenuTitle,
  MenuWrapper,
} from './styles';

import logoWhiteColor from '../../assets/images/logo-supersonic-branca-verde.svg';
import { useAuth } from '../../hooks/auth';

interface MenuProps {
  items: {
    type: 'link' | 'a';
    url: string;
    icon: IconDefinition;
    text: string;
  }[];
}

const Menu: React.FC<MenuProps> = ({ items }) => {
  const { user, logout } = useAuth();
  const [mobileShown, setMobileShown] = useState(false);

  useEffect(() => {
    setMobileShown(false);
    document.querySelector('body')?.classList.remove('stop-scroll');
  }, []);

  function toggleMobileMenu() {
    const classes = document.querySelector('body')?.classList;
    if (!mobileShown) classes?.add('stop-scroll');
    else classes?.remove('stop-scroll');
    setMobileShown(!mobileShown);
  }

  return (
    <>
      <MenuMobileOpen>
        <div className="menu-mobile-logo">
          <img src={logoWhiteColor} alt="Logo Supersonic" />
        </div>
        <button type="button" onClick={toggleMobileMenu}>
          Menu
          <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
        </button>
      </MenuMobileOpen>
      <MenuFixedWrapper mobileShown={mobileShown}>
        <button
          type="button"
          className="menu-mobile-close"
          onClick={toggleMobileMenu}
        >
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </button>
        <MenuWrapper>
          <MenuContainer>
            <img src={logoWhiteColor} alt="Logo Supersonic" />
            {user.admin && <MenuTitle>ADMIN</MenuTitle>}
            {user.client && <MenuTitle>CLIENT</MenuTitle>}
            <MenuItem>
              {items.map(item => {
                if (item.type === 'link') {
                  return (
                    <Link key={item.url} to={item.url}>
                      <FontAwesomeIcon fixedWidth icon={item.icon} />
                      {item.text}
                    </Link>
                  );
                }

                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon fixedWidth icon={item.icon} />
                    {item.text}
                  </a>
                );
              })}
              <button type="button" onClick={() => logout()}>
                <FontAwesomeIcon fixedWidth icon={faSignOutAlt} />
                Logout
              </button>
            </MenuItem>
          </MenuContainer>
          <MenuMobileOptions>
            <button type="button">Contato</button>
            <a
              href="https://3.basecamp.com/3115942/"
              target="_blank"
              rel="noreferrer"
            >
              Basecamp
            </a>
            <a href="https://supersonic.ag" target="_blank" rel="noreferrer">
              Site Supersonic
            </a>
          </MenuMobileOptions>
        </MenuWrapper>
      </MenuFixedWrapper>
    </>
  );
};

export default Menu;
