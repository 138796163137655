import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import logoColor from '../../assets/images/logo-supersonic-colorida.svg';

import { Wrapper, Container, Links, LoginError } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface LoginFormData {
  email: string;
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [resetError, setResetError] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      try {
        formRef.current?.setErrors({});
        setResetError(false);
        setResetLoading(true);

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          password: Yup.string()
            .min(8, 'A senha deve possuir no mínimo 8 caracteres')
            .required('Nova senha obrigatória'),
          password_confirmation: Yup.string()
            .min(8, 'A senha deve possuir no mínimo 8 caracteres')
            .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error('Token inválido');
        }

        await api.post('/password/reset', {
          token,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });

        history.push('/login?reset');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else if (error.response) {
          const msg = error.response.data.message;
          if (msg === 'Token already used') {
            setErrorMessage('Este token já foi usado, gere um novo.');
          } else if (msg === 'Token expired') {
            setErrorMessage('O token expirou, gere um novo.');
          } else {
            setErrorMessage(
              'O token é inválido, tente novamente ou gere um novo.',
            );
          }
          setResetError(true);
        }
        setResetLoading(false);
      }
    },
    [history, location.search],
  );

  return (
    <>
      <Wrapper>
        <Container className="default-box-shadow">
          <img src={logoColor} alt="Logo Supersonic" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Alteração de senha</p>
            <Input name="email" id="email" label="Confirme seu e-mail" />
            <Input
              name="password"
              id="password"
              label="Digite a nova senha"
              type="password"
            />
            <Input
              name="password_confirmation"
              id="password_confirmation"
              label="Confirme a nova senha"
              type="password"
            />
            <Button
              type="submit"
              isLoading={resetLoading}
              className="gradient-supersonic"
            >
              Trocar senha
            </Button>
            {resetError && <LoginError>{errorMessage}</LoginError>}
          </Form>
        </Container>
        <Links>
          <Link to="/">Voltar ao início</Link>
        </Links>
      </Wrapper>
    </>
  );
};

export default ResetPassword;
