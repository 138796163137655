import styled from 'styled-components';

interface ContainerProps {
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
  border: 1px solid var(--gray-light);
  padding: 10px;
  border-radius: 5px;

  input {
    display: flex;
    padding: 10px 10px;
    border: 1px solid
      ${props => (props.isErrored ? 'var(--red)' : 'var(--gray-light)')};
    transition: all 200ms;
    align-items: center;
  }

  label {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 200ms;
    color: ${props => (props.isErrored ? 'var(--red)' : 'var(--black)')};
    cursor: default;
    margin-left: 7px;
  }

  input:focus {
    border-color: var(--green-text);
  }
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 2px;
  text-align: right;
`;

export const InputCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  p {
    color: var(--red);
    margin-left: 8px;
  }
`;
