import { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useAuth } from './auth';
import ProjectFull from '../entities/ProjectFull';

export interface ProjectState {
  id: string;
  project: ProjectFull;
}
type ProjectStateArray = Array<ProjectState>;

interface UserProjectsContextData {
  projects: ProjectStateArray;
}

export const UserProjectsContext = createContext<UserProjectsContextData>(
  {} as UserProjectsContextData,
);

export const UserProjectsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [data, setData] = useState<ProjectStateArray>([]);

  useEffect(() => {
    async function loadProjectsAssignedToUser() {
      if (!user) return;

      const response = await api.get(`/projects/user/${user.id}`);

      const projectFull: ProjectStateArray = response.data;

      const projectsNew = projectFull.map(projectState => {
        const stepblocksOrdered = projectState.project.stepblocks.sort(
          (a, b) => {
            return a.order - b.order;
          },
        );

        const stepblocksWithStepsOrdered = stepblocksOrdered.map(stepblocks => {
          const newSteps = stepblocks.steps.sort((x, y) => {
            return x.order - y.order;
          });

          return {
            ...stepblocks,
            steps: newSteps,
          };
        });

        return {
          id: projectState.id,
          project: {
            ...projectState.project,
            stepblocks: stepblocksWithStepsOrdered,
          },
        };
      });

      setData(projectsNew);
    }
    loadProjectsAssignedToUser();
  }, [user]);

  return (
    <UserProjectsContext.Provider value={{ projects: data }}>
      {children}
    </UserProjectsContext.Provider>
  );
};

export function useUserProjects(): UserProjectsContextData {
  const context = useContext(UserProjectsContext);

  if (!context) {
    throw new Error('useAuth must be used within an UserProjectsProvider');
  }

  return context;
}
