import React, { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import { Line, defaults } from 'react-chartjs-2';

import Container from './styles';

interface ChartLineProps {
  chartData: ChartData;
}

const ChartLine: React.FC<ChartLineProps> = ({ chartData }) => {
  const [chartDataFormatted, setChartDataFormatted] = useState<ChartData>(
    {} as ChartData,
  );
  const { global, line } = defaults;

  const options = {
    ...global,
    ...line,
  };

  useEffect(() => {
    const config = [
      {
        color: '#2dd45c',
        background: '#2dd45c30',
      },
      {
        color: '#57479c',
        background: '#57479c30',
      },
    ];

    if (chartData.datasets) {
      const newChartData: ChartData = {
        labels: chartData.labels,
        datasets: chartData.datasets.map((dataset, index) => {
          return {
            ...dataset,
            borderColor: config[index].color,
            backgroundColor: config[index].background,
            lineTension: 0,
            borderWidth: 2,
            pointBackgroundColor: config[index].color,
          };
        }),
      };
      setChartDataFormatted(newChartData);
    }
  }, [chartData]);

  // console.log('chart data component', chartData);
  return (
    <Container>
      {chartDataFormatted.datasets && (
        <Line data={chartDataFormatted} options={options} />
      )}
    </Container>
  );
};

export default ChartLine;
