import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import AdminDashProjectListPaginated from '../../../components/AdminDashProjectListPaginated';
import ContainerGlobal from '../../../components/ContainerGlobal';
import DashboardContainer from '../../../components/DashboardContainer';
import DashboardTitle from '../../../components/DashboardTitle';
import DashboardTopMenu from '../../../components/DashboardTopMenu';
import Menu from '../../../components/Menu';
import { useAuth } from '../../../hooks/auth';
import { useProject } from '../../../hooks/projects';
import AdminMenuObjects from '../../../objects/adminMenu';
import {
  WidgetContainer,
  Widget,
  WidgetTitle,
  WidgetNumberContainer,
  WidgetNumber,
} from './styles';
import { LoadingIcon } from '../../../styles/global';
import { EditingButton } from '../Project/styles';
import Input2 from '../../../components/Input2';
import Button from '../../../components/Button';
import { CreateStepError } from '../Step/styles';
import Whitebox from '../../../components/Whitebox';

interface CreateProjectData {
  company_name: string;
  project_name: string;
}

const AdminDashboardComponent: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { projects, createProject } = useProject();

  const [creatingProject, setCreatingProject] = useState(false);
  const [createProjectError, setCreateProjectError] = useState('');

  const createProjectFormRef = useRef<FormHandles>(null);

  const ongoingProjectsNumber = useMemo(() => {
    return projects.filter(project => project.status === 'ongoing').length;
  }, [projects]);
  const finishedProjectsNumber = useMemo(() => {
    return projects.filter(project => project.status === 'finished').length;
  }, [projects]);

  const handleCreateProjectSubmit = useCallback(
    async (data: CreateProjectData) => {
      if (!data.project_name) {
        setCreateProjectError('Digite o nome do projeto');
        return;
      }

      if (!data.company_name) {
        setCreateProjectError('Digite o nome da empresa');
        return;
      }

      setCreatingProject(true);

      const projectId = await createProject(
        data.project_name,
        data.company_name,
      ).finally(() => setCreatingProject(false));

      history.push(`/admin/project?project_id=${projectId}`);
    },
    [createProject, history],
  );

  return (
    <>
      <ContainerGlobal>
        <Menu items={AdminMenuObjects.items} />
        <DashboardContainer>
          <DashboardTopMenu />
          <WidgetContainer>
            <Widget>
              <WidgetTitle>{`Olá ${user.name.split(' ')[0]}!`}</WidgetTitle>
              <p>Este é o painel administrativo do App Supersonic.</p>
            </Widget>
            {projects.length === 0 && (
              <>
                <Widget className="green2">
                  <LoadingIcon>
                    <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                  </LoadingIcon>
                </Widget>
                <Widget className="green1">
                  <LoadingIcon>
                    <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                  </LoadingIcon>
                </Widget>
              </>
            )}
            {projects.length > 0 && (
              <>
                <Widget className="green2">
                  <WidgetNumberContainer>
                    <WidgetNumber>{ongoingProjectsNumber}</WidgetNumber>
                    <p>
                      {`projeto${
                        ongoingProjectsNumber > 1 ? 's' : ''
                      } em andamento`}
                    </p>
                  </WidgetNumberContainer>
                </Widget>
                <Widget className="green1">
                  <WidgetNumberContainer>
                    <WidgetNumber>{finishedProjectsNumber}</WidgetNumber>
                    <p>
                      {`projeto${finishedProjectsNumber > 1 ? 's' : ''}
                    finalizado${finishedProjectsNumber > 1 ? 's' : ''}`}
                    </p>
                  </WidgetNumberContainer>
                </Widget>
              </>
            )}
          </WidgetContainer>
          <DashboardTitle>Projetos</DashboardTitle>
          <AdminDashProjectListPaginated items={projects} itemsPerPage={5} />
          <Whitebox>
            <h1>Criar projeto</h1>
            <Form
              ref={createProjectFormRef}
              onSubmit={handleCreateProjectSubmit}
            >
              <Input2
                name="project_name"
                id="project_name"
                label="Nome do projeto"
              />
              <Input2
                name="company_name"
                id="company_name"
                label="Nome da empresa"
              />
              {createProjectError && (
                <CreateStepError>{createProjectError}</CreateStepError>
              )}
              <EditingButton>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  isLoading={creatingProject}
                >
                  Criar projeto
                </Button>
              </EditingButton>
            </Form>
          </Whitebox>
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default AdminDashboardComponent;
