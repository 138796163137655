import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';
import logoColor from '../../assets/images/logo-supersonic-colorida.svg';

import { Wrapper, Container, ForgotMessage, Links } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface LoginFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [forgotMessage, setForgotMessage] = useState(false);
  const [forgotLoading, setForgotLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: LoginFormData) => {
    try {
      formRef.current?.setErrors({});
      setForgotMessage(false);
      setForgotLoading(true);

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/password/forgot', {
        email: data.email,
      });

      setForgotMessage(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef.current?.setErrors(errors);
      }
      setForgotLoading(false);
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Container className="default-box-shadow">
          <img src={logoColor} alt="Logo Supersonic" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Recuperação de senha</p>
            <Input name="email" id="email" label="E-mail para recuperação" />
            {!forgotMessage && (
              <Button
                type="submit"
                isLoading={forgotLoading}
                className="gradient-supersonic"
              >
                Recuperar senha
              </Button>
            )}
          </Form>
          {forgotMessage && (
            <ForgotMessage>
              Você receberá um e-mail com as instruções caso esse e-mail
              pertença a alguma conta.
            </ForgotMessage>
          )}
        </Container>
        <Links>
          <Link to="/">Voltar ao início</Link>
        </Links>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;
