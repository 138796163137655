import Airtable from 'airtable';
import Record from 'airtable/lib/record';

interface AirtableRecordsProps {
  records: Record[];
}

export default async function loadTestFromAirtable(
  filter: string,
): Promise<AirtableRecordsProps | undefined> {
  if (
    !process.env.REACT_APP_AIRTABLE_API_KEY ||
    !process.env.REACT_APP_AIRTABLE_API_PROJECT
  ) {
    return undefined;
  }

  let tempRecords: Record[] = [];

  const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  }).base(process.env.REACT_APP_AIRTABLE_API_PROJECT);

  await base('Experimentos')
    .select({
      view: 'Registro',
      fields: [
        'ID',
        'Cliente',
        'Número do Teste',
        'Título do Teste',
        'Estágio',
        'Data de Início',
        'Data de Fim',
        'Resultado',
        'Significância',
        'Lift',
        'experiment_id',
        'ga_id',
        'metric_1',
        'metric_1_name',
        'metric_2',
        'metric_2_name',
        'metric_3',
        'metric_3_name',
      ],
      pageSize: 100,
      // eslint-disable-next-line prettier/prettier
      filterByFormula: filter,
      sort: [{ field: 'ID', direction: 'desc' }],
    })
    .eachPage(function page(records, fetchNextPage) {
      tempRecords = [...tempRecords, ...records];

      fetchNextPage();
    });

  return {
    records: tempRecords,
  };
}
