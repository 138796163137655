import React, { useCallback } from 'react';
import { useModal } from '../../hooks/Modal';

import { Container, MenuContainer, MenuItem, ContatoTeste } from './styles';

const DashboardTopMenu: React.FC = () => {
  const { showModal } = useModal();

  const showContatoModal = useCallback(async () => {
    const modalContatoContent = <ContatoTeste>...</ContatoTeste>;

    showModal({
      title: 'Contato',
      content: modalContatoContent,
    });
  }, [showModal]);

  return (
    <Container>
      <MenuContainer>
        <MenuItem>
          <button type="button" onClick={showContatoModal}>
            Contato
          </button>
          <a
            href="https://3.basecamp.com/3115942/"
            target="_blank"
            rel="noreferrer"
          >
            Basecamp
          </a>
          <a href="https://supersonic.ag" target="_blank" rel="noreferrer">
            Site Supersonic
          </a>
        </MenuItem>
      </MenuContainer>
    </Container>
  );
};

export default DashboardTopMenu;
