import styled, { createGlobalStyle, keyframes } from 'styled-components';

export const loadingBackgroundAnimation2 = keyframes`
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

export default createGlobalStyle`
  :root {
    --green-text: #2dd45c;
    --background-color: #f3f6f1;
    --black: #1b1b1b;
    --gray: #9d9f9c;
    --gray-light: #bfc2c4;
    --gray-lighter: #ebeced;
    --gray-background: #e0e0e0;
    --gray-blue: #59677A;
    --green1: #b5ea3e;
    --green2: #7be14b;
    --green-light: #eafaee;
    --red: #E53035;
    --red-light: #FFEBEE;
    --yellow: #ffcf6f;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background-color: var(--background-color);
    -webkit-font-smoothing: antialiased;
  }

  .stop-scroll {
    overflow: hidden;
  }

  .gradient-supersonic {
    background: linear-gradient(45deg, #b5ea3e 0%, #7be14b 100%);
  }

  .default-box-shadow {
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
  }

  .overflow-hidden {
    overflow: hidden;
  }

  body, input, button {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: var(--black);
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
    --webkit-appearance: none;
  }

  form {
    width: 100%;
    margin-top: 10px;
  }

  ::selection {
    background-color: var(--green-text);
    color: white;
  }
  .pagination-container {
    justify-content: center;
    align-items: center;
    margin: 15px 10px;
  }
  .prevButton,
  .nextButton,
  .pageButton {
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: white;
    margin: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
    &:hover {
      background-color: var(--green2);
      color: white;
    }
    &:disabled {
      background-color: rgba(255,255,255,0.2);
      color: rgba(0,0,0,0.2) !important;
    }
  }
  .navButtonSelected {
      background-color: var(--green2);
      color: white;
  }
  .btn {
    border: none;
    padding: 15px 35px;
    border-radius: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    min-width: 180px;
    max-width: 250px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    text-align: center;
  }
  .btn-primary {
    background: linear-gradient(90deg, #7be14b, #b5ea3e, #7be14b, #b5ea3e);
    color: white;
    background-size: 400% 100%;
    background-position: 100% 0%;
  }
  .btn-primary:disabled {
    opacity: 0.5;
  }
  .btn-primary:hover {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${loadingBackgroundAnimation2};
    animation-timing-function: linear;
  }
  .btn-secondary {
    color: var(--black);
    background: var(--gray-background);
  }
  .btn-center {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-small {
    padding: 10px 10px;
    margin-top: 0;
  }
  .mobile {
    display: none !important;
  }
  .desktop {
    display: flex !important;
  }
  @media (max-width: 767px) {
    .mobile {
      display: flex !important;
    }
    .desktop {
      display: none !important;
    }
  }
`;

export const loadingBackgroundAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const WidgetLoading = styled.section`
  border-radius: 15px;
  background-color: gray;
  display: flex;
  flex: 1;
  margin-right: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(250, 250, 240, 1) 0%,
    rgba(181, 234, 62, 0.3) 100%
  );
  background-size: 200% 200%;
  animation: ${loadingBackgroundAnimation} 4s ease-in-out infinite;
`;

export const WidgetLoading2 = styled.section`
  display: flex;
  flex: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-right: 20px;
  border-radius: 15px;
  animation-duration: 15s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: ${loadingBackgroundAnimation2};
  animation-timing-function: linear;
  background-size: 1100% 100%;
  background-image: linear-gradient(
    90deg,
    rgb(250, 250, 250),
    #e2e2e2,
    rgb(250, 250, 250),
    #e2e2e2,
    rgb(250, 250, 250),
    #e2e2e2,
    rgb(250, 250, 250),
    #e2e2e2,
    rgb(250, 250, 250),
    #e2e2e2,
    rgb(250, 250, 250)
  );
`;

export const LoadingIcon = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: var(--black);
  padding: 30px;
`;

export const ErrorBox = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 15px;
  background-color: var(--red-light);
  color: var(--red);
  border: 1px solid var(--red);
`;
