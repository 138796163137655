import { useEffect, useState } from 'react';
import { createContext, useCallback, useContext } from 'react';
import generator from 'generate-password';
import User from '../entities/User';
import api from '../services/api';

interface CreateUserData {
  name: string;
  email: string;
  password: string;
  admin: boolean;
  client: boolean;
}

interface UpdateUserData {
  id: string;
  name: string;
  email: string;
  password?: string;
  admin: boolean;
  client: boolean;
}

interface UsersContextData {
  createUser(createUserData: CreateUserData): Promise<void>;
  updateUser(updateUserData: UpdateUserData): Promise<void>;
  setUserForEditing(user_id: string): void;
  clearUserForEdit(): void;
  generateRandomPassword(): string;
  users: User[];
  userEditing: User;
}

export const UsersContext = createContext<UsersContextData>(
  {} as UsersContextData,
);

export const UsersProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [userEditing, setUserEditing] = useState<User>({} as User);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  const generateRandomPassword = useCallback(() => {
    return generator.generate({
      length: 10,
      numbers: true,
      excludeSimilarCharacters: true,
    });
  }, []);

  const clearUserForEdit = useCallback(() => {
    setUserEditing({} as User);
  }, []);

  const setUserForEditing = useCallback(
    (user_id: string) => {
      const userForEditing = users.find(userListing => {
        return userListing.id === user_id;
      });
      if (userForEditing) {
        setUserEditing(userForEditing);
      } else {
        setUserEditing({} as User);
      }
    },
    [users],
  );

  const createUser = useCallback(
    async ({ name, email, password, admin, client }: CreateUserData) => {
      const response = await api.post('users', {
        name,
        email,
        password,
        admin,
        client,
      });

      const user = response.data;

      setUsers([user, ...users]);
    },
    [users],
  );

  const updateUser = useCallback(
    async ({ id, name, email, password, admin, client }: UpdateUserData) => {
      await api.patch('users', {
        id,
        name,
        email,
        password,
        admin,
        client,
      });
    },
    [],
  );

  return (
    <UsersContext.Provider
      value={{
        createUser,
        users,
        clearUserForEdit,
        userEditing,
        updateUser,
        setUserForEditing,
        generateRandomPassword,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export function useUsers(): UsersContextData {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error('useUsers must be used within an UsersProviders');
  }

  return context;
}
