import styled from 'styled-components';

import { StatusCircleProps } from './index';

const Container = styled.section<StatusCircleProps>`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 0;
    left: 0;
    ${props => {
      if (props.color === 'green') {
        return 'background-color: #7EE14A; border: 3px solid #DBFFC7;';
      }
      if (props.color === 'yellow') {
        return 'background-color: #FFCF6F; border: 3px solid #F7F795';
      }

      return 'background-color: #2B343D; border: 3px solid #ffffffcc';
    }};
    border-radius: 100%;
  }
`;

export default Container;
