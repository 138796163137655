import styled from 'styled-components';

const TableContainer = styled.table`
  margin: 15px 0px;
  border: 1px solid var(--gray-lighter);

  thead tr {
    background-color: var(--gray-lighter);
  }
  th {
    padding: 10px;
    text-align: left;
  }
  thead tr:last-child {
    background-color: #ebeced70;
  }
  tbody td {
    padding: 10px;
    text-align: left;
  }
  tbody tr:nth-of-type(even) {
    background-color: #ebeced50;
  }
`;

export default TableContainer;
