import React from 'react';

import { AuthProvider } from './auth';
import { ModalProvider } from './Modal';
import { UserProjectsProvider } from './userProjects';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ModalProvider>
      <UserProjectsProvider>{children}</UserProjectsProvider>
    </ModalProvider>
  </AuthProvider>
);

export default AppProvider;
