import {
  faHome,
  faLock,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

interface MenuProps {
  items: {
    type: 'link' | 'a';
    url: string;
    icon: IconDefinition;
    text: string;
  }[];
}

const AdminMenuObjects: MenuProps = {
  items: [
    {
      type: 'link',
      url: '/admin/dashboard',
      icon: faHome,
      text: 'Início / Projetos',
    },
    {
      type: 'link',
      url: '/admin/users',
      icon: faUsers,
      text: 'Gerenciamento de usuários',
    },
    {
      type: 'link',
      url: '/change-password',
      icon: faLock,
      text: 'Alterar senha',
    },
  ],
};

export default AdminMenuObjects;
