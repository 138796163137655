import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  padding: 10px;
  margin-top: 20px;
  color: var(--red);
  background-color: var(--red-light);
  border: 1px solid var(--red);
  border-radius: 5px;
`;

export default Container;
