import styled from 'styled-components';

export const Container = styled.div`
  label {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
  }

  p {
    margin-top: 15px;
  }
`;

export const ProjectTestsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0px 0px 0px;

  div {
    padding: 10px;
    background-color: var(--gray-lighter);
    border-radius: 8px;

    svg {
      margin-left: 10px;
      cursor: pointer;
    }
    svg:hover {
      opacity: 0.6;
    }
  }
`;

export const TestDetailContainer = styled.section`
  border: 1px solid var(--gray-lighter);
  padding: 15px;
  margin: 10px 0px 0px 0px;

  strong {
    margin-right: 10px;
  }

  P {
    border-bottom: 1px solid var(--gray-lighter);
    padding-bottom: 10px;
  }

  p:first-child {
    margin-top: 0px;
  }

  p:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;
