import styled, { keyframes } from 'styled-components';

const ModalAnimation = keyframes`
  0% {
    opacity: 0;
    top: 48%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
`;

export const ModalOverlay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99998;
`;

export const ModalContainer = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  min-width: 500px;
  max-width: 700px;
  animation: 300ms ${ModalAnimation} ease-in-out;

  @media (max-width: 767px) {
    min-width: auto;
    width: calc(100% - 40px);
    transform: translate(calc(-50% - 20px), -50%);
    margin: auto 20px;
  }
`;

export const ModalHeader = styled.section`
  padding: 20px 55px 20px 20px;
  font-weight: bold;
  color: var(--gray);
  letter-spacing: 1px;
  border-bottom: 1px solid var(--gray-lighter);
  position: relative;
`;

export const ModalTitle = styled.span`
  text-transform: uppercase;
`;

export const ModalClose = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 18px;

  svg {
    color: var(--gray-light);
  }
`;

export const ModalContent = styled.section`
  padding: 20px;
`;
