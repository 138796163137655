import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  margin: 30px 0 15px 0;
  padding-bottom: 8px;
  color: var(--gray);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  border-bottom: 1px solid var(--gray-light);
`;

export default Container;
