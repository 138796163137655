import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.section`
  margin: 30px 15px 15px 15px;
  display: flex;
  width: 95%;
  max-width: 400px;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  form {
    margin-top: 30px;

    p {
      text-align: center;
      color: var(--black);
      margin-bottom: 15px;
    }
  }

  a {
    margin-top: 20px;
    text-decoration: none;
    color: var(--gray);
    transition: all 200ms;
  }
  a:hover {
    color: var(--black);
  }

  img {
    max-width: 200px;
  }
`;

export const Links = styled.section`
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;

  a {
    color: var(--gray);
    text-decoration: none;
    font-size: 16px;
    margin: 0 5px;
    padding: 0 5px;
    transition: all 200ms;
  }
  a:hover {
    color: var(--black);
  }
`;

export const ForgotMessage = styled.div`
  padding: 10px 15px;
  color: var(--green-text);
  border: 1px solid var(--green-text);
  border-radius: 8px;
  background-color: var(--green-light);
  margin: 15px 0px;
`;
