import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import AdminDashUserListPaginated from '../../../components/AdminDashUserListPaginated';
import Button from '../../../components/Button';
import ContainerGlobal from '../../../components/ContainerGlobal';
import DashboardContainer from '../../../components/DashboardContainer';
import DashboardTitle from '../../../components/DashboardTitle';
import DashboardTopMenu from '../../../components/DashboardTopMenu';
import FormError from '../../../components/FormError';
import Input2 from '../../../components/Input2';
import InputCheckbox from '../../../components/InputCheckbox';
import { InputCheckboxContainer } from '../../../components/InputCheckbox/styles';
import Menu from '../../../components/Menu';
import Whitebox from '../../../components/Whitebox';
import { useModal } from '../../../hooks/Modal';
import { useUsers } from '../../../hooks/users';
import AdminMenuObjects from '../../../objects/adminMenu';
import getValidationErrors from '../../../utils/getValidationErrors';

interface CreateUserFormData {
  name: string;
  email: string;
  password: string;
  admin: boolean;
  client: boolean;
}

const AdminUsers: React.FC = () => {
  const { createUser, users, generateRandomPassword } = useUsers();
  const { showModal } = useModal();
  const createUserFormRef = useRef<FormHandles>(null);
  const [creatingUser, setCreatingUser] = useState(false);
  const [creatingError, setCreatingError] = useState(false);
  const [createCheckboxError, setCreateCheckboxError] = useState('');
  const [createUserError, setCreateUserError] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setPassword(generateRandomPassword());
  }, [generateRandomPassword]);

  const handleSubmit = useCallback(
    async (data: CreateUserFormData) => {
      createUserFormRef.current?.setErrors({});
      setCreateCheckboxError('');
      setCreateUserError('');
      setCreatingError(false);

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome'),
          email: Yup.string()
            .required('Digite o e-mail')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .required('Digite a senha')
            .min(8, 'A senha deve possuir no mínimo 8 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!data.admin && !data.client) {
          setCreateCheckboxError('Marque pelo menos uma das opções.');
          throw new Error();
        }

        if (data.admin && data.client) {
          setCreateCheckboxError('Marque somente uma das opções.');
          throw new Error();
        }

        setCreatingUser(true);
        await createUser(data)
          .catch(err => {
            if (err instanceof XMLHttpRequest) {
              const error = JSON.parse(err.response);
              if (error.message === 'E-mail already registered.') {
                setCreateUserError('Esse e-mail já está registrado.');
              } else {
                setCreateUserError(
                  `Ocorreu um erro ao criar o usuário:
                  ${error.message} (${error.status})`,
                );
              }
            }
            setCreatingError(true);
          })
          .finally(() => setCreatingUser(false));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          createUserFormRef.current?.setErrors(errors);
        }
      }
    },
    [createUser],
  );

  function showHelpAccountType() {
    showModal({
      title: 'Tipo de conta',
      content: (
        <>
          <p>
            <strong>Cliente:</strong>

            {` Uma conta cliente mostra as informações de projetos específicos, serve para os clientes Supersonic.`}
          </p>
          <br />
          <p>
            <strong>Administrador:</strong>

            {` Esse tipo de conta administra os projetos e os usuários do sistema.`}
          </p>
        </>
      ),
    });
  }

  return (
    <>
      <ContainerGlobal>
        <Menu items={AdminMenuObjects.items} />
        <DashboardContainer>
          <DashboardTopMenu />
          <Whitebox>
            <h1>Criar usuário</h1>
            <Form onSubmit={handleSubmit} ref={createUserFormRef}>
              <Input2 name="name" id="name" label="Nome do usuário" />
              <Input2 name="email" id="email" label="E-mail" />
              <Input2
                name="password"
                id="password"
                label="Senha"
                defaultValue={password}
                readOnly
                copy
              />
              <InputCheckboxContainer>
                <InputCheckbox
                  type="checkbox"
                  name="client"
                  id="client"
                  label="Cliente"
                />
                <InputCheckbox
                  type="checkbox"
                  name="admin"
                  id="admin"
                  label="Administrador"
                />
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ cursor: 'pointer' }}
                  onClick={showHelpAccountType}
                />
                {createCheckboxError.length > 0 && <p>{createCheckboxError}</p>}
              </InputCheckboxContainer>
              <Button
                type="submit"
                className="btn btn-primary btn-center"
                isLoading={creatingUser}
                isErrored={creatingError}
              >
                Criar usuário
              </Button>
              {createUserError && <FormError>{createUserError}</FormError>}
            </Form>
          </Whitebox>
          <DashboardTitle>Usuários cadastrados</DashboardTitle>
          <AdminDashUserListPaginated items={users} itemsPerPage={5} />
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default AdminUsers;
