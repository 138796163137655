import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 15px 0px;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    justify-content: center;
    margin-top: 20px;
    ul {
      display: none;
    }
  }
`;

export const MenuContainer = styled.ul``;

export const MenuItem = styled.li`
  list-style: none;

  @media (max-width: 767px) {
    a:first-child,
    button:first-child {
      margin-left: 0px;
    }
  }

  a,
  button {
    border: none;
    background-color: transparent;
    margin-left: 20px;
    color: var(--gray);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 11px;
    }
  }
`;

export const ContatoTeste = styled.p`
  font-size: 18px;
  color: var(--gray);
`;
