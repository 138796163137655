/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-curly-newline */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ContainerGlobal from '../../../components/ContainerGlobal';
import DashboardContainer from '../../../components/DashboardContainer';
import DashboardTopMenu from '../../../components/DashboardTopMenu';
import Menu from '../../../components/Menu';
import { useProject } from '../../../hooks/projects';
import AdminMenuObjects from '../../../objects/adminMenu';

import {
  ButtonsContainer,
  CreateStepblock,
  CreateStepblockContainer,
  CreateStepblockError,
  EditingButton,
  Stepblock,
  Stepblocks,
  StepMoveable,
  StepName,
  StepOptions,
} from './styles';
import Input2 from '../../../components/Input2';
import { LoadingIcon } from '../../../styles/global';
import Button from '../../../components/Button';
import { useModal } from '../../../hooks/Modal';
import Step from '../Step';
import { ErrorText } from '../Step/styles';
import Whitebox from '../../../components/Whitebox';

interface ProjectFormData {
  project_name: string;
  company_name: string;
}

interface StepBlockNameData {
  target: {
    value: string;
    id: string;
  };
}

const AdminProject: React.FC = () => {
  const {
    projectEditing,
    setProjectForEdit,
    changeStepblockOrder,
    updateStepblocks,
    changeStepblockName,
    removeStepblock,
    createStepblock,
    saveProject,
  } = useProject();
  const history = useHistory();
  const { showModal, hideModal } = useModal();

  const [savingStepblocks, setSavingStepblocks] = useState(false);
  const [savingProject, setSavingProject] = useState(false);
  const [createStepblockError, setCreateStepblockError] = useState(false);
  const [saveProjectError, setSaveProjectError] = useState('');
  const [saveStepblockError, setSaveStepblockError] = useState('');

  const formRef = useRef<FormHandles>(null);
  const createStepblockInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { project_id } = queryString.parse(history.location.search);
    if (project_id && typeof project_id === 'string') {
      setProjectForEdit(project_id);
    }
  }, [history.location.pathname, history.location.search, setProjectForEdit]);

  const stepblockMoveUp = useCallback(
    (stepblock_id: string, actual_order: number) => {
      changeStepblockOrder(stepblock_id, actual_order - 1);
    },
    [changeStepblockOrder],
  );

  const stepblockMoveDo = useCallback(
    (stepblock_id: string, actual_order: number) => {
      changeStepblockOrder(stepblock_id, actual_order + 1);
    },
    [changeStepblockOrder],
  );

  const updateProjectName = useCallback(
    ({ target }: StepBlockNameData) => {
      setSaveStepblockError('');
      if (!target.value) {
        setSaveStepblockError('Digite o nome da etapa');
      }
      changeStepblockName(target.id, target.value);
    },
    [changeStepblockName],
  );

  const handleSaveStepblocks = useCallback(async () => {
    if (saveStepblockError.length > 0) {
      return;
    }
    setSavingStepblocks(true);
    updateStepblocks().finally(() => {
      setSavingStepblocks(false);
    });
  }, [saveStepblockError.length, updateStepblocks]);

  const stepblockRemove = useCallback(
    (stepblock_id: string) => {
      setSavingStepblocks(true);
      hideModal();
      removeStepblock(stepblock_id).finally(() => {
        setSavingStepblocks(false);
      });
    },
    [hideModal, removeStepblock],
  );

  const removeStepblockModal = useCallback(
    (stepblock_id: string, block_name: string) => {
      showModal({
        content: (
          <>
            <p>{`Nome da etapa: ${block_name}`}</p>
            <div>
              <Button onClick={() => stepblockRemove(stepblock_id)}>
                Sim, remover
              </Button>
              <Button onClick={() => hideModal()}>Cancelar</Button>
            </div>
          </>
        ),
        title: 'Confirma a remoção da etapa?',
      });
    },
    [hideModal, showModal, stepblockRemove],
  );

  const handleRemoveStepblock = useCallback(
    (stepblock_id: string, block_name: string) => {
      removeStepblockModal(stepblock_id, block_name);
    },
    [removeStepblockModal],
  );

  const renderStepBlocks = useMemo(() => {
    if (projectEditing) {
      if (projectEditing.stepblocks) {
        if (projectEditing.stepblocks.length === 0) {
          return <ErrorText>Não existem etapas criadas.</ErrorText>;
        }
        return projectEditing.stepblocks
          .sort((stepblockA, stepblockB) => {
            return stepblockA.order - stepblockB.order;
          })
          .map(stepblock => {
            return (
              <Stepblock key={stepblock.id}>
                <StepMoveable>
                  {stepblock.order > 1 && (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      fixedWidth
                      onClick={() =>
                        stepblockMoveUp(stepblock.id, stepblock.order)
                      }
                    />
                  )}
                  {stepblock.order === 1 && (
                    <FontAwesomeIcon
                      className="chevron-disabled"
                      icon={faChevronUp}
                      fixedWidth
                    />
                  )}
                  <span>{stepblock.order}</span>
                  {stepblock.order < projectEditing.stepblocks.length && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fixedWidth
                      onClick={() =>
                        stepblockMoveDo(stepblock.id, stepblock.order)
                      }
                    />
                  )}
                  {stepblock.order === projectEditing.stepblocks.length && (
                    <FontAwesomeIcon
                      className="chevron-disabled"
                      icon={faChevronDown}
                      fixedWidth
                    />
                  )}
                </StepMoveable>
                <StepName>
                  <input
                    onBlur={updateProjectName}
                    type="text"
                    name="block_name"
                    id={stepblock.id}
                    defaultValue={stepblock.block_name}
                  />
                </StepName>
                <StepOptions>
                  <FontAwesomeIcon
                    icon={faTrash}
                    fixedWidth
                    onClick={() =>
                      handleRemoveStepblock(stepblock.id, stepblock.block_name)
                    }
                  />
                </StepOptions>
              </Stepblock>
            );
          });
      }
    }
    return null;
  }, [
    handleRemoveStepblock,
    projectEditing,
    stepblockMoveDo,
    stepblockMoveUp,
    updateProjectName,
  ]);

  const handleCreateStep = useCallback(() => {
    const stepblock_name = createStepblockInputRef.current?.value;

    if (!stepblock_name) {
      setCreateStepblockError(true);
      return;
    }

    setSavingStepblocks(true);
    createStepblock(stepblock_name).finally(() => {
      setSavingStepblocks(false);
      createStepblockInputRef.current!.value = '';
    });
  }, [createStepblock]);

  const handleSubmit = useCallback(
    (data: ProjectFormData) => {
      setSaveProjectError('');

      if (!data.project_name) {
        setSaveProjectError('Digite o nome do projeto');
        return;
      }

      if (!data.company_name) {
        setSaveProjectError('Digite o nome da empresa');
        return;
      }

      setSavingProject(true);
      saveProject(data.project_name, data.company_name).finally(() => {
        setSavingProject(false);
      });
    },
    [saveProject],
  );

  return (
    <>
      <ContainerGlobal>
        <Menu items={AdminMenuObjects.items} />
        <DashboardContainer>
          <DashboardTopMenu />
          <Whitebox>
            <h1>Edição de projeto</h1>
            {!projectEditing.id && (
              <LoadingIcon>
                <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
              </LoadingIcon>
            )}
            {projectEditing.id && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input2
                  type="text"
                  name="project_name"
                  id="project_name"
                  label="Nome do projeto"
                  defaultValue={projectEditing.project_name}
                />
                <Input2
                  type="text"
                  name="company_name"
                  id="company_name"
                  label="Nome da empresa do cliente"
                  defaultValue={projectEditing.company_name}
                />
                {saveProjectError.length > 0 && (
                  <CreateStepblockError>
                    {saveProjectError}
                  </CreateStepblockError>
                )}
                <EditingButton>
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    isLoading={savingProject}
                  >
                    Salvar informações do projeto
                  </Button>
                </EditingButton>
              </Form>
            )}
          </Whitebox>
          <Whitebox>
            <h1>Edição das etapas</h1>
            {!projectEditing.id && (
              <LoadingIcon>
                <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
              </LoadingIcon>
            )}
            {projectEditing.id && (
              <Stepblocks>
                {projectEditing.id && renderStepBlocks}
                {saveStepblockError.length > 0 && (
                  <CreateStepblockError>
                    {saveStepblockError}
                  </CreateStepblockError>
                )}
                <CreateStepblockContainer>
                  <label htmlFor="stepblock_create_name">
                    Criar uma nova etapa
                  </label>
                  <CreateStepblock>
                    <input
                      type="text"
                      name="stepblock_name"
                      id="stepblock_create_name"
                      placeholder="Nome da etapa"
                      ref={createStepblockInputRef}
                    />
                    <Button
                      className="btn btn-primary btn-small"
                      onClick={() => handleCreateStep()}
                    >
                      Criar etapa
                    </Button>
                  </CreateStepblock>
                  {createStepblockError && (
                    <CreateStepblockError>
                      Digite o nome da etapa
                    </CreateStepblockError>
                  )}
                </CreateStepblockContainer>
                <ButtonsContainer>
                  <Button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSaveStepblocks}
                    isLoading={savingStepblocks}
                  >
                    Salvar etapas
                  </Button>
                </ButtonsContainer>
              </Stepblocks>
            )}
          </Whitebox>
          <Whitebox>
            <Step />
          </Whitebox>
          <Whitebox>
            <h1>Testes AB</h1>
            <Link
              to={`/admin/project/tests?project_id=${projectEditing.id}`}
              className="btn btn-primary"
            >
              Administrar testes
            </Link>
          </Whitebox>
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default AdminProject;
