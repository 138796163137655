import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import Button from '../../components/Button';
import ContainerGlobal from '../../components/ContainerGlobal';
import DashboardContainer from '../../components/DashboardContainer';
import DashboardTopMenu from '../../components/DashboardTopMenu';
import FormError from '../../components/FormError';
import Input2 from '../../components/Input2';
import Menu from '../../components/Menu';
import Whitebox from '../../components/Whitebox';
import { useAuth } from '../../hooks/auth';
import AdminMenuObjects from '../../objects/adminMenu';
import ClientMenuObjects from '../../objects/clientMenu';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

interface ChangePasswordData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const ChangePassword: React.FC = () => {
  const { user } = useAuth();
  const [changingPassword, setChangingPassword] = useState(false);
  const [changingPasswordError, setChangingPasswordError] = useState('');

  const changePasswordFormRef = useRef<FormHandles>(null);

  async function handleChangePassword(data: ChangePasswordData) {
    setChangingPasswordError('');
    changePasswordFormRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        old_password: Yup.string()
          .required('Digite a senha atual')
          .min(8, 'A senha deve possuir no mínimo 8 caracteres'),
        password: Yup.string()
          .required('Digite a nova senha')
          .min(8, 'A senha deve possuir no mínimo 8 caracteres'),
        password_confirmation: Yup.string()
          .required('Digite a confirmação da nova senha')
          .min(8, 'A senha deve possuir no mínimo 8 caracteres')
          .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setChangingPassword(true);

      await api
        .patch('/users/password/change', {
          user_id: user.id,
          old_password: data.old_password,
          password: data.password,
          password_confirmation: data.password_confirmation,
        })
        .catch(err => {
          const error = JSON.parse(err.response);
          if (err instanceof XMLHttpRequest) {
            if (error.message === 'Incorrect old password.') {
              setChangingPasswordError('A senha atual não coincide.');
            }
          } else {
            setChangingPasswordError(
              `Ocorreu um erro ao alterar a senha:
              ${error.message} (${error.status})`,
            );
          }
          setChangingPassword(false);
        })
        .finally(() => setChangingPassword(false));
    } catch (err) {
      setChangingPassword(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        changePasswordFormRef.current?.setErrors(errors);
      }
    }
  }

  return (
    <>
      <ContainerGlobal>
        {user.admin && <Menu items={AdminMenuObjects.items} />}
        {user.client && <Menu items={ClientMenuObjects.items} />}
        <DashboardContainer>
          <DashboardTopMenu />
          <Whitebox>
            <h1>Alterar sua senha</h1>
            <Form ref={changePasswordFormRef} onSubmit={handleChangePassword}>
              <Input2
                type="password"
                name="old_password"
                id="old_password"
                label="Senha atual"
              />
              <Input2
                type="password"
                name="password"
                id="password"
                label="Nova senha"
              />
              <Input2
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                label="Confirmação da nova senha"
              />
              <Button
                type="submit"
                className="btn btn-primary btn-center"
                isLoading={changingPassword}
                isErrored={!!changingPasswordError}
              >
                Alterar senha
              </Button>
              {changingPasswordError.length > 0 && (
                <FormError>{changingPasswordError}</FormError>
              )}
            </Form>
          </Whitebox>
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default ChangePassword;
