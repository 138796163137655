import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  error => {
    if (error.response) {
      if (
        error.response.config.url !== 'login' &&
        error.response.status === 401
      ) {
        localStorage.removeItem('@Supersonic:token');
        localStorage.removeItem('@Supersonic:user');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error.request);
  },
);

export default api;
