/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

import { Container, Error } from './styles';

interface InputProps extends SelectProps<OptionTypeBase, boolean> {
  name: string;
  containerStyle?: React.CSSProperties;
  label?: string;
}

const Select: React.FC<InputProps> = ({
  name,
  id,
  containerStyle = {},
  label = '',
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle} isErrored={!!error}>
      {label && <label htmlFor={id}>{label}</label>}
      <ReactSelect
        defaultValue={defaultValue}
        name={name}
        id={id}
        ref={selectRef}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Select;
