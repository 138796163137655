import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  label?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  id,
  containerStyle = {},
  label = '',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [inputEmpty, setInputEmpty] = useState(true);

  const handleInputChange = useCallback(() => {
    setInputEmpty(!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });

    setInputEmpty(!inputRef.current?.value);
  }, [fieldName, registerField]);

  return (
    <Container style={containerStyle} isErrored={!!error}>
      <input
        id={id}
        defaultValue={defaultValue}
        onChange={handleInputChange}
        ref={inputRef}
        {...rest}
      />
      {label && (
        <label htmlFor={id} className={inputEmpty ? '' : 'not-empty'}>
          {label}
        </label>
      )}
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Input;
