import styled from 'styled-components';

const Container = styled.section`
  margin-left: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-left: 0px;
  }
`;

export default Container;
