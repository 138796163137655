import styled from 'styled-components';

interface MenuProps {
  mobileShown?: boolean;
}

export const MenuMobileOpen = styled.div`
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  padding: 0 30px;

  display: none;

  @media (max-width: 767px) {
    display: flex;
  }

  > button {
    display: flex;
    align-items: center;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 10px;
    border: none;
    background-color: transparent;
  }

  .menu-mobile-logo {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    img {
      max-width: 130px;
    }
  }
`;

export const MenuFixedWrapper = styled.div<MenuProps>`
  display: flex;
  min-width: 300px;

  @media (max-width: 1024px) {
    min-width: 240px;
  }

  @media (max-width: 767px) {
    position: fixed;
    z-index: 99;
    min-width: auto;
    width: 100%;
    height: 100%;
    left: 0px;
    transition: all 300ms ease-in;
    top: ${props => (props.mobileShown ? '0px' : '-150%')};
  }

  .menu-mobile-close {
    display: none;
  }

  @media (max-width: 767px) {
    .menu-mobile-close {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      color: white;
      z-index: 99;
      border: none;
      background-color: transparent;
      padding: 10px;
    }
  }
`;

export const MenuWrapper = styled.section`
  padding: 30px;
  background-color: var(--black);
  min-width: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 600px;
  position: fixed;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 1024px) {
    min-width: 240px;
    padding: 20px;
  }
  @media (max-width: 767px) {
    min-width: auto;
    width: 100%;
    border-radius: 0;
  }
`;

export const MenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: calc(300px - 60px);

  img {
    margin-bottom: 5px;
    max-width: 150px;
    align-self: center;
  }

  @media (max-width: 1024px) {
    min-width: calc(240px - 40px);
  }
`;

export const MenuItem = styled.li`
  list-style: none;
  display: block;

  a:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  a,
  button {
    width: 100%;
    text-align: left;
    display: block;
    color: white;
    text-decoration: none;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 200ms;
    background-color: transparent;
    border-right: none;
    border-left: none;
    border-top: none;

    @media (max-width: 1024px) {
      font-size: 15px;
    }
  }
  a:hover,
  button:hover {
    color: var(--green-text);
  }

  svg {
    margin-right: 10px;
  }
`;

export const MenuTitle = styled.p`
  color: var(--green1);
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 30px;
`;

export const MenuMobileOptions = styled.div`
  align-items: center;
  flex-direction: column;
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }

  a,
  button {
    text-decoration: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: white;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
`;
