import styled from 'styled-components';

interface ContainerProps {
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15px;

  input {
    display: flex;
    flex: 1;
    padding: 10px 10px;
    border: 1px solid
      ${props => (props.isErrored ? 'var(--red)' : 'var(--gray-light)')};
    transition: all 200ms;
  }

  label {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 200ms;
    padding-bottom: 5px;
    color: ${props => (props.isErrored ? 'var(--red)' : 'var(--black)')};
    cursor: text;
  }

  input:focus {
    border-color: var(--green-text);
  }
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 2px;
  text-align: right;
`;
