import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
  select {
    padding: 10px 5px;
    margin: 10px 0;
    border: 1px solid var(--gray-light);
    border-radius: 5px;
  }
`;

export default Container;

export const ErrorText = styled.p`
  margin-bottom: 15px;
  color: var(--red);
`;

export const StepEditingContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;

  label {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 5px;
    color: var(--black);
    display: block;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--gray-light);
  }
`;

export const StepRadios = styled.div`
  display: flex;
  margin-top: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px 0 10px;
    background-color: white;
    border-top: 1px solid var(--gray-light);
    border-left: 1px solid var(--gray-light);
    border-bottom: 1px solid var(--gray-light);

    &:last-child {
      border-right: 1px solid var(--gray-light);
    }

    label {
      margin-top: 0 !important;
      padding: 15px 0 !important;
      letter-spacing: 0px;
    }
  }

  input {
    width: auto;
    margin: 0 8px 0 0px;
  }
`;

export const CreateStep = styled.div`
  position: relative;
  margin: 10px 0;
  padding: 10px 15px 10px 15px;
  background-color: #f3f3f3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 10px;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--gray);
    width: 100%;
    flex: 1;
    margin: 8px 0px;
  }
  button {
    width: auto;
    margin-top: 8px;
    margin-left: 0;
  }
  p {
    font-size: 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
`;

export const CreateStepContainer = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid var(--gray-light);
`;

export const CreateStepError = styled.div`
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 15px;
  background-color: var(--red-light);
  color: var(--red);
  border: 1px solid var(--red);
`;

export const CreateStepRadios = styled.div`
  display: flex;
  margin: 5px 0;

  div {
    margin-right: 5px;
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-light);
    padding-right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-size: 12px;

    input {
      width: 30px;
    }
  }
`;
