import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { useAuth } from '../../hooks/auth';
import logoColor from '../../assets/images/logo-supersonic-colorida.svg';

import {
  Wrapper,
  Container,
  Links,
  LoginError,
  SuccessMessage,
} from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

interface LoginFormData {
  email: string;
  password: string;
}

interface LoginTypeReturn {
  admin: boolean;
  client: boolean;
}

const Login: React.FC = () => {
  const [loginError, setLoginError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { login } = useAuth();

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      try {
        formRef.current?.setErrors({});
        setLoginError(false);
        setLoginLoading(true);

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const loginType: LoginTypeReturn = await login({
          email: data.email,
          password: data.password,
        });
        if (loginType.admin) {
          history.push('/admin/dashboard');
        } else {
          history.push('/dashboard');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }
        setLoginError(true);
        setLoginLoading(false);
      }
    },
    [history, login],
  );

  useEffect(() => {
    if (location.search.indexOf('reset') !== -1) {
      setResetSuccess(true);
    } else {
      setResetSuccess(false);
    }
  }, [location.search]);

  return (
    <>
      <Wrapper>
        <Container className="default-box-shadow">
          <img src={logoColor} alt="Logo Supersonic" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            {resetSuccess && (
              <SuccessMessage>
                A sua senha foi alterada com sucesso.
              </SuccessMessage>
            )}
            <Input
              name="email"
              id="email"
              label="Digite seu e-mail"
              defaultValue={
                process.env.REACT_APP_LOGIN_EMAIL
                  ? process.env.REACT_APP_LOGIN_EMAIL
                  : ''
              }
            />
            <Input
              name="password"
              id="password"
              label="Digite sua senha"
              type="password"
              defaultValue={
                process.env.REACT_APP_LOGIN_PASSWORD
                  ? process.env.REACT_APP_LOGIN_PASSWORD
                  : ''
              }
            />
            <Button
              type="submit"
              isLoading={loginLoading}
              className="gradient-supersonic"
            >
              Entrar
            </Button>
            {loginError && <LoginError>Credenciais incorretas.</LoginError>}
          </Form>
          <Link to="/forgot-password">Esqueci a senha</Link>
        </Container>
        <Links>
          <a
            href="https://supersonic.ag"
            title="Supersonic Website"
            target="_blank"
            rel="noreferrer"
          >
            Página inicial
          </a>
          <a
            href="https://supersonic.ag/blog"
            title="Supersonic Blog"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
          <a
            href="https://instagram.com/supersonic.ag"
            title="Instagram Supersonic"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </Links>
      </Wrapper>
    </>
  );
};

export default Login;
