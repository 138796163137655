import { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import User from '../entities/User';

interface AuthState {
  token: string;
  user: User;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginTypeReturn {
  admin: boolean;
  client: boolean;
}

interface AuthContextData {
  user: User;
  login(credentials: LoginCredentials): Promise<LoginTypeReturn>;
  logout(): void;
  updateUser(user: User): void;
  blockEverything(): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Supersonic:token');
    const user = localStorage.getItem('@Supersonic:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      try {
        const userFromJson = JSON.parse(user);
        return { token, user: userFromJson };
      } catch (err) {
        return {} as AuthState;
      }
    }

    return {} as AuthState;
  });
  const [noAuth, setNoAuth] = useState(false);

  const blockEverything = useCallback(() => {
    setNoAuth(true);
  }, []);

  const login = useCallback(
    async ({ email, password }): Promise<LoginTypeReturn> => {
      const response = await api.post('login', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@Supersonic:token', token);
      localStorage.setItem('@Supersonic:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });

      return {
        admin: user.admin,
        client: user.client,
      };
    },
    [],
  );

  const logout = useCallback(() => {
    localStorage.removeItem('@Supersonic:token');
    localStorage.removeItem('@Supersonic:user');
    localStorage.clear();

    setData({} as AuthState);

    history.push('/login');
  }, [history]);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Supersonic:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, login, logout, updateUser, blockEverything }}
    >
      {!noAuth && children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
