import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ModalClose,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalOverlay,
} from './styles';

interface ModalData {
  title: string;
  content: React.ReactNode;
}

interface ModalContextData {
  showModal(modalData: ModalData): void;
  hideModal(): void;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

export const ModalProvider: React.FC = ({ children }) => {
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const [modalTitle, setModalTitle] = useState('');
  const [shown, setShown] = useState(false);

  const overlayRef = useRef<HTMLElement>(null);

  const showModal = useCallback(({ title, content }: ModalData): void => {
    setShown(true);
    setModalTitle(title);
    setModalContent(content);
  }, []);

  const hideModal = useCallback(() => {
    setShown(false);
  }, []);

  const handleModalOverlayFocus = useCallback(
    event => {
      if (overlayRef.current && overlayRef.current === event.target) {
        hideModal();
      }
    },
    [hideModal],
  );

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {shown && (
        <ModalOverlay
          ref={overlayRef}
          tabIndex={0}
          onFocus={handleModalOverlayFocus}
        >
          <ModalContainer tabIndex={0} className="default-box-shadow">
            <ModalHeader>
              <ModalTitle>{modalTitle}</ModalTitle>
              <ModalClose onClick={hideModal}>
                <FontAwesomeIcon icon={faTimes} />
              </ModalClose>
            </ModalHeader>
            <ModalContent>{modalContent}</ModalContent>
          </ModalContainer>
        </ModalOverlay>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used whitin a ModalProvider');
  }

  return context;
}
