import React from 'react';

import Container from './styles';

export interface StatusCircleProps {
  color: 'green' | 'yellow' | 'gray';
}

const StatusCircle: React.FC<StatusCircleProps> = ({ color }) => {
  return <Container className="mobile" color={color} />;
};

export default StatusCircle;
