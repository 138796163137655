/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Airtable from 'airtable';
import queryString from 'query-string';
import Record from 'airtable/lib/record';
import { useHistory } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ReactSelect from 'react-select';
import { format } from 'date-fns';
import ContainerGlobal from '../../../components/ContainerGlobal';
import DashboardContainer from '../../../components/DashboardContainer';
import DashboardTopMenu from '../../../components/DashboardTopMenu';
import Menu from '../../../components/Menu';
import Whitebox from '../../../components/Whitebox';
import { useProject } from '../../../hooks/projects';
import AdminMenuObjects from '../../../objects/adminMenu';
import {
  Container,
  ProjectTestsContainer,
  TestDetailContainer,
} from './styles';
import api from '../../../services/api';
import { ErrorBox, LoadingIcon } from '../../../styles/global';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import loadTestFromAirtable from '../../../services/airtable';
import TestDataView from '../TestDataView';

interface AirtableRecordsProps {
  records: Record[];
}

interface SelectClientProps {
  value: string;
  label: string;
}

interface ProjectTest {
  airtable_client_name: string;
  airtable_end: Date;
  airtable_start: Date;
  airtable_id: number;
  airtable_lift: string;
  airtable_result: string;
  airtable_significance: string;
  airtable_stage: string;
  airtable_test_number: number;
  airtable_title: string;
  experiment_id: string;
  created_at: Date;
  id: string;
  project_id: string;
  ga_id: string;
  metric_1: string;
  metric_1_name: string;
  metric_2: string;
  metric_2_name: string;
  metric_3: string;
  metric_3_name: string;
}

interface AddTestsToProjectFormData {
  select_client: string;
  selected_tests: [];
}

const AdminProjectTests: React.FC = () => {
  const { projectEditing, setProjectForEdit } = useProject();
  const history = useHistory();
  const [airtableClients, setAirtableClients] = useState<AirtableRecordsProps>({
    records: [],
  });
  const [airtableTests, setAirtableTests] = useState<AirtableRecordsProps>({
    records: [],
  });
  const [selectClients, setSelectClients] = useState<SelectClientProps[]>([]);
  const [selectTests, setSelectTests] = useState<SelectClientProps[]>([]);
  const [projectTests, setProjectTests] = useState<ProjectTest[]>([]);

  const [loadingProject, setLoadingProject] = useState(true);

  const [noTests, setNoTests] = useState(false);

  const [addToProjectError, setAddToProjectError] = useState('');

  const [addingToProject, setAddingToProject] = useState(false);
  const [errorAddingToProject, setErrorAddingToProject] = useState(false);

  const [updatingTest, setUpdatingTest] = useState(false);
  const [updatingTestError, setUpdatingTestError] = useState(false);

  const [testShownId, setTestShownId] = useState('');

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (projectEditing.id && projectEditing.id.length > 0) {
      return;
    }

    const { project_id } = queryString.parse(history.location.search);
    if (project_id && typeof project_id === 'string') {
      setProjectForEdit(project_id);
    }
  }, [
    history.location.pathname,
    history.location.search,
    projectEditing.id,
    setProjectForEdit,
  ]);

  useEffect(() => {
    setLoadingProject(true);

    async function loadTests(project_id: string) {
      return api.get(`/analytics/project-tests/project/${project_id}`);
    }

    const { project_id } = queryString.parse(history.location.search);
    if (project_id && typeof project_id === 'string') {
      loadTests(project_id).then(response => {
        const dbProjectTests: ProjectTest[] = response.data;

        setProjectTests(dbProjectTests);
        setLoadingProject(false);
      });
    }
  }, [history.location.search]);

  useEffect(() => {
    async function loadRecordsFromAirtable() {
      if (
        !process.env.REACT_APP_AIRTABLE_API_KEY ||
        !process.env.REACT_APP_AIRTABLE_API_PROJECT
      ) {
        return;
      }

      if (!projectEditing.id) {
        return;
      }

      let tempRecords: Record[] = [];

      const base = new Airtable({
        apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
      }).base(process.env.REACT_APP_AIRTABLE_API_PROJECT);

      base('Clientes')
        .select({
          view: 'Grid view',
          fields: ['Nome'],
          pageSize: 100,
          sort: [{ field: 'Nome', direction: 'asc' }],
        })
        .eachPage(function page(records, fetchNextPage) {
          tempRecords = [...tempRecords, ...records];

          fetchNextPage();
        })
        .then(() => {
          setAirtableClients({
            records: tempRecords,
          });
        });
    }
    loadRecordsFromAirtable();
  }, [projectEditing]);

  useEffect(() => {
    const clientsToSelect = airtableClients.records
      .filter(record => {
        if (!record.fields.Nome) return false;

        return true;
      })
      .map(record => {
        return {
          value: record.fields.Nome,
          label: record.fields.Nome,
        };
      });
    setSelectClients(clientsToSelect);
  }, [airtableClients]);

  function handleSelectClientesChange(value: OptionTypeBase | null) {
    if (
      !process.env.REACT_APP_AIRTABLE_API_KEY ||
      !process.env.REACT_APP_AIRTABLE_API_PROJECT
    ) {
      return;
    }

    if (!value!.label) {
      return;
    }

    let tempRecords: Record[] = [];

    let filter = `AND({Cliente} = '${value!.label}', {experiment_id} != '', `;

    projectTests.forEach(projectTest => {
      filter += `{experiment_id} != '${projectTest.experiment_id}', `;
    });

    filter = filter.substring(0, filter.length - 2);

    filter += ')';

    const base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(process.env.REACT_APP_AIRTABLE_API_PROJECT);

    base('Experimentos')
      .select({
        view: 'Registro',
        fields: [
          'ID',
          'Cliente',
          'Número do Teste',
          'Título do Teste',
          'Estágio',
          'Data de Início',
          'Data de Fim',
          'Resultado',
          'Significância',
          'Lift',
          'experiment_id',
          'ga_id',
          'metric_1',
          'metric_1_name',
          'metric_2',
          'metric_2_name',
          'metric_3',
          'metric_3_name',
        ],
        pageSize: 100,
        // eslint-disable-next-line prettier/prettier
        filterByFormula: filter,
        sort: [{ field: 'ID', direction: 'desc' }],
      })
      .eachPage(function page(records, fetchNextPage) {
        tempRecords = [...tempRecords, ...records];

        fetchNextPage();
      })
      .then(() => {
        setAirtableTests({
          records: tempRecords,
        });

        if (tempRecords.length === 0) {
          setNoTests(true);
        } else {
          setNoTests(false);
        }
      });
  }

  useEffect(() => {
    const testsToSelect = airtableTests.records
      .filter(record => {
        if (!record.fields.Cliente) return false;

        return true;
      })
      .map(record => {
        return {
          value: JSON.stringify(record.fields),
          label: `#${record.fields['Número do Teste']} - ${record.fields['Título do Teste']}`,
        };
      });
    setSelectTests(testsToSelect);
  }, [airtableTests]);

  async function removeTestFromProject(test_id: string) {
    await api.delete(`/analytics/project-tests/${test_id}`);

    const newProjectTests = projectTests.filter(projectTest => {
      return projectTest.id !== test_id;
    });

    if (testShownId === test_id) {
      setTestShownId('');
    }

    setProjectTests(newProjectTests);
  }

  const handleAddTestToProject = useCallback(
    (data: AddTestsToProjectFormData) => {
      const { project_id } = queryString.parse(history.location.search);
      if (!project_id || typeof project_id !== 'string') {
        return;
      }

      const mappedProjectTests = data.selected_tests.map(selectedTest => {
        return JSON.parse(selectedTest);
      });

      const projectTestsRequest = mappedProjectTests.map(projectTest => {
        return {
          project_id,
          experiment_id: projectTest.experiment_id,
          airtable_client_name: data.select_client,
          airtable_id: projectTest.ID,
          airtable_start: projectTest['Data de Início'],
          airtable_end: projectTest['Data de Fim'],
          airtable_stage: projectTest['Estágio'],
          airtable_lift: projectTest.Lift,
          airtable_test_number: projectTest['Número do Teste'],
          airtable_result: projectTest.Resultado,
          airtable_title: projectTest['Título do Teste'],
          airtable_significance: projectTest['Significância'],
          ga_id: projectTest.ga_id,
          metric_1: projectTest.metric_1,
          metric_1_name: projectTest.metric_1_name,
          metric_2: projectTest.metric_2,
          metric_2_name: projectTest.metric_2_name,
          metric_3: projectTest.metric_3,
          metric_3_name: projectTest.metric_3_name,
        };
      });

      setErrorAddingToProject(false);
      setAddingToProject(true);

      api
        .post('/analytics/project-tests', {
          projectTests: projectTestsRequest,
        })
        .then(response => {
          const responseProjectTests: ProjectTest[] = response.data;
          setProjectTests(responseProjectTests);
          setAddingToProject(false);
        })
        .catch(error => {
          setErrorAddingToProject(true);
          setAddingToProject(false);
          const response = JSON.parse(error.response);
          if (response.message) {
            if (response.message.indexOf('Duplicate') !== -1) {
              setAddToProjectError('O teste já foi adicionado ao projeto.');
            }
          }
        });
    },
    [history.location.search],
  );

  function handleSelectProjectTestChange(
    value: { value: string; label: string } | null,
  ) {
    if (value && value.value) {
      setTestShownId(value.value);
    } else {
      setTestShownId('');
    }
  }

  const renderTestDetail = useMemo(() => {
    const projectTest = projectTests.find(ptest => {
      return ptest.id === testShownId;
    });

    if (!projectTest) return null;

    const start = format(new Date(projectTest.airtable_start), 'dd/MM/yyyy');
    const end = projectTest.airtable_end
      ? format(new Date(projectTest.airtable_end), 'dd/MM/yyyy')
      : '-';

    return (
      <TestDetailContainer>
        <p>
          <strong>Nome do teste:</strong>
          {projectTest.airtable_title}
        </p>
        <p>
          <strong>Número do teste:</strong>
          {projectTest.airtable_test_number}
        </p>
        <p>
          <strong>Início do teste:</strong>
          {start}
        </p>
        <p>
          <strong>Fim do teste:</strong>
          {end}
        </p>
        <p>
          <strong>Estágio:</strong>
          {projectTest.airtable_stage}
        </p>
        <p>
          <strong>Lift:</strong>
          {projectTest.airtable_lift}
        </p>
        <p>
          <strong>Resultado:</strong>
          {projectTest.airtable_result}
        </p>
        <p>
          <strong>Significância:</strong>
          {projectTest.airtable_significance}
        </p>
        <p style={{ display: 'flex' }}>
          <strong>Experiment ID:</strong>
          <input
            style={{ flex: 1 }}
            value={projectTest.experiment_id}
            readOnly
          />
        </p>
        <p>
          <strong>Métrica 1:</strong>
          {projectTest.metric_1_name}
        </p>
        <p>
          <strong>Métrica 2:</strong>
          {projectTest.metric_2_name}
        </p>
        <p>
          <strong>Métrica 3:</strong>
          {projectTest.metric_3_name}
        </p>
      </TestDetailContainer>
    );
  }, [projectTests, testShownId]);

  async function updateTestFromAirtable(test_id: string) {
    if (
      !process.env.REACT_APP_AIRTABLE_API_KEY ||
      !process.env.REACT_APP_AIRTABLE_API_PROJECT
    ) {
      return;
    }

    if (!test_id) {
      return;
    }

    const test = projectTests.find(projectTest => {
      return projectTest.id === testShownId;
    });

    if (!test) {
      return;
    }

    setUpdatingTest(true);

    const filter = `{ID} = '${test.airtable_id}'`;

    const result = await loadTestFromAirtable(filter);

    if (!result) {
      setUpdatingTestError(true);
      setUpdatingTest(false);
      return;
    }

    const fields = result?.records[0].fields;

    const request = {
      experiment_id: fields.experiment_id,
      airtable_id: fields.ID,
      airtable_client_name: test.airtable_client_name,
      airtable_test_number: fields['Número do Teste'],
      airtable_title: fields['Título do Teste'],
      airtable_start: fields['Data de Início'],
      airtable_end: fields['Data de Fim'],
      airtable_stage: fields['Estágio'],
      airtable_lift: fields.Lift,
      airtable_result: fields.Resultado,
      airtable_significance: fields['Significância'],
      ga_id: fields.ga_id,
      metric_1: fields.metric_1,
      metric_1_name: fields.metric_1_name,
      metric_2: fields.metric_2,
      metric_2_name: fields.metric_2_name,
      metric_3: fields.metric_3,
      metric_3_name: fields.metric_3_name,
    };

    const response = await api.put('/analytics/project-tests', request);

    const updatedTest: ProjectTest = response.data;

    const newTests = projectTests.map(projectTest => {
      if (projectTest.id === updatedTest.id) {
        return updatedTest;
      }
      return projectTest;
    });

    setProjectTests(newTests);
    setUpdatingTest(false);
  }

  return (
    <>
      <ContainerGlobal>
        <Menu items={AdminMenuObjects.items} />
        <DashboardContainer>
          <DashboardTopMenu />
          <Whitebox>
            <Container>
              <h1>Integração com o Airtable</h1>
              <p>
                <strong>{`Projeto interno selecionado: `}</strong>
                {`${projectEditing.project_name} | ${projectEditing.company_name}`}
              </p>
              {loadingProject && (
                <LoadingIcon>
                  <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
                </LoadingIcon>
              )}
              <Form ref={formRef} onSubmit={handleAddTestToProject}>
                {!loadingProject && selectClients.length > 0 && (
                  <>
                    <label htmlFor="select-clientes">
                      Selecione o cliente do Airtable para carregar seus testes:
                    </label>
                    <Select
                      id="select-clientes"
                      options={selectClients}
                      placeholder="Selecione o cliente"
                      onChange={handleSelectClientesChange}
                      name="select_client"
                      isMulti={false}
                    />
                  </>
                )}
                {!loadingProject && selectTests.length > 0 && (
                  <>
                    <label htmlFor="select-tests">
                      Selecione os testes que deseja atribuir ao projeto:
                    </label>
                    <Select
                      id="select-tests"
                      options={selectTests}
                      placeholder="Selecione um ou mais testes"
                      name="selected_tests"
                      isMulti
                    />
                    <Button
                      type="submit"
                      className="btn btn-primary btn-center"
                      isLoading={addingToProject}
                      isErrored={errorAddingToProject}
                    >
                      Adicionar testes ao projeto
                    </Button>
                    {addToProjectError.length > 0 && (
                      <ErrorBox>{addToProjectError}</ErrorBox>
                    )}
                  </>
                )}
              </Form>
              {noTests && (
                <>
                  <p>
                    <strong>
                      Nenhum teste preparado para o sistema foi encontrado.
                    </strong>
                  </p>
                  <p>
                    É necessário inserir (no Airtable) o ID do experimento em
                    cada teste que será exibido no sistema.
                  </p>
                </>
              )}
            </Container>
          </Whitebox>
          <Whitebox>
            <h1>Testes do projeto</h1>
            {loadingProject && (
              <LoadingIcon>
                <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
              </LoadingIcon>
            )}
            <ProjectTestsContainer>
              {!loadingProject &&
                projectTests.map(projectTest => {
                  return (
                    <div key={projectTest.id}>
                      {`#${projectTest.airtable_test_number} - ${projectTest.airtable_title}`}
                      <FontAwesomeIcon
                        icon={faTrash}
                        fixedWidth
                        onClick={() => removeTestFromProject(projectTest.id)}
                      />
                    </div>
                  );
                })}
              {!loadingProject && projectTests.length === 0 && (
                <p>Nenhum teste foi atribuído ao projeto.</p>
              )}
            </ProjectTestsContainer>
          </Whitebox>
          <Whitebox>
            <Container>
              <h1>Detalhes do teste</h1>
              {!loadingProject && projectTests.length === 0 && (
                <p>Nenhum teste foi atribuído ao projeto.</p>
              )}
              {projectTests.length > 0 && (
                <>
                  <label htmlFor="select-project-test">
                    Selecione os testes que deseja atribuir ao projeto:
                  </label>
                  <ReactSelect
                    id="select-project-test"
                    name="select-project-test"
                    onChange={handleSelectProjectTestChange}
                    options={projectTests.map(projectTest => {
                      return {
                        value: projectTest.id,
                        label: `#${projectTest.airtable_test_number} - ${projectTest.airtable_title}`,
                      };
                    })}
                  />
                </>
              )}
              {testShownId && renderTestDetail}
              {testShownId && (
                <Button
                  type="button"
                  onClick={() => updateTestFromAirtable(testShownId)}
                  isLoading={updatingTest}
                  isErrored={updatingTestError}
                >
                  Atualizar informações (Airtable)
                </Button>
              )}
            </Container>
          </Whitebox>
          {testShownId && (
            <TestDataView
              experiment_id={
                projectTests.find(projectTest => {
                  return projectTest.id === testShownId;
                })?.experiment_id
              }
            />
          )}
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default AdminProjectTests;
