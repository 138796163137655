import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  label?: string;
  copy?: boolean;
}

const Input2: React.FC<InputProps> = ({
  name,
  id,
  containerStyle = {},
  label = '',
  copy = false,
  ...rest
}) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const copyToClipboard = useCallback(() => {
    const data = inputRef.current?.value;
    if (data) {
      navigator.clipboard.writeText(data);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container style={containerStyle} copy={copy} isErrored={!!error}>
      {label && <label htmlFor={id}>{label}</label>}
      <input id={id} defaultValue={defaultValue} ref={inputRef} {...rest} />
      {copy && (
        <button type="button" onClick={copyToClipboard}>
          {!copied && <FontAwesomeIcon icon={faCopy} />}
          {copied && <FontAwesomeIcon icon={faCheck} />}
        </button>
      )}
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Input2;
