import {
  faQuestionCircle,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import ContainerGlobal from '../../../components/ContainerGlobal';
import DashboardContainer from '../../../components/DashboardContainer';
import DashboardTopMenu from '../../../components/DashboardTopMenu';
import FormError from '../../../components/FormError';
import Input2 from '../../../components/Input2';
import InputCheckbox from '../../../components/InputCheckbox';
import { InputCheckboxContainer } from '../../../components/InputCheckbox/styles';
import Menu from '../../../components/Menu';
import Whitebox from '../../../components/Whitebox';
import { useModal } from '../../../hooks/Modal';
import { useUsers } from '../../../hooks/users';
import AdminMenuObjects from '../../../objects/adminMenu';
import getValidationErrors from '../../../utils/getValidationErrors';
import Select from '../../../components/Select';
import { useProject } from '../../../hooks/projects';
import api from '../../../services/api';
import { ProjectUser, ProjectUserContainer } from './styles';
import { LoadingIcon } from '../../../styles/global';

interface UpdateUserData {
  id: string;
  name: string;
  email: string;
  password?: string;
  admin: boolean;
  client: boolean;
}

interface AddUserToProjectData {
  projectToUser: string;
}

interface UserProjects {
  project_id: string;
  user_id: string;
  project: {
    id: string;
    company_name: string;
    project_name: string;
  };
}
type UserProjectsArray = Array<UserProjects>;

interface UserProjectsRemoveIcon {
  project_id: string;
  removing: boolean;
}
type UserProjectsRemoveIconArray = Array<UserProjectsRemoveIcon>;

const AdminUser: React.FC = () => {
  const {
    userEditing,
    updateUser,
    setUserForEditing,
    generateRandomPassword,
  } = useUsers();
  const { projects } = useProject();
  const { showModal } = useModal();
  const history = useHistory();
  const editUserFormRef = useRef<FormHandles>(null);
  const [editingUser, setEditingUser] = useState(false);
  const [editingError, setEditingError] = useState(false);
  const [editCheckboxError, setEditCheckboxError] = useState('');
  const [editUserError, setEditUserError] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const [randomPassword, setRandomPassword] = useState('');
  const [addingToProject, setAddingToProject] = useState(false);
  const [userProjects, setUserProjects] = useState([] as UserProjectsArray);
  const [userProjectsRemove, setUserProjectsRemove] = useState(
    [] as UserProjectsRemoveIconArray,
  );
  const [loadingUserProjects, setLoadingUserProjects] = useState(false);

  useEffect(() => {
    const { user_id } = queryString.parse(history.location.search);
    if (user_id && typeof user_id === 'string') {
      setUserForEditing(user_id);
    }
    setRandomPassword(generateRandomPassword());
  }, [
    generateRandomPassword,
    history.location.pathname,
    history.location.search,
    setUserForEditing,
  ]);

  useEffect(() => {
    async function loadProjectsAssignedToUser() {
      const { user_id } = queryString.parse(history.location.search);
      if (user_id && typeof user_id === 'string') {
        const response = await api.get(`projects/user/${user_id}`);
        const userProjectsFromResponse: UserProjectsArray = response.data;
        setUserProjects(userProjectsFromResponse);
        setUserProjectsRemove(
          userProjectsFromResponse.map(userProject => {
            return {
              project_id: userProject.project_id,
              removing: false,
            };
          }),
        );
      }
    }
    setLoadingUserProjects(true);
    loadProjectsAssignedToUser().finally(() => setLoadingUserProjects(false));
  }, [history.location.search, userEditing.id]);

  const handleSubmit = useCallback(
    async (data: UpdateUserData) => {
      editUserFormRef.current?.setErrors({});
      setEditCheckboxError('');
      setEditUserError('');
      setEditingError(false);

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome'),
          email: Yup.string()
            .required('Digite o e-mail')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .optional()
            .min(8, 'A senha deve possuir no mínimo 8 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!data.admin && !data.client) {
          setEditCheckboxError('Marque pelo menos uma das opções.');
          throw new Error();
        }

        if (data.admin && data.client) {
          setEditCheckboxError('Marque somente uma das opções.');
          throw new Error();
        }

        setEditingUser(true);

        const dataForUpdate = {
          ...data,
          id: userEditing.id,
        };

        await updateUser(dataForUpdate)
          .catch(err => {
            if (err instanceof XMLHttpRequest) {
              const error = JSON.parse(err.response);
              if (error.message === 'E-mail already registered.') {
                setEditUserError('Esse e-mail já está registrado.');
              } else {
                setEditUserError(
                  `Ocorreu um erro ao editar o usuário:
                  ${error.message} (${error.status})`,
                );
              }
            }
            setEditingError(true);
          })
          .finally(() => setEditingUser(false));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          editUserFormRef.current?.setErrors(errors);
        }
      }
    },
    [updateUser, userEditing.id],
  );

  function showHelpAccountType() {
    showModal({
      title: 'Tipo de conta',
      content: (
        <>
          <p>
            <strong>Cliente:</strong>

            {` Uma conta cliente mostra as informações de projetos específicos, serve para os clientes Supersonic.`}
          </p>
          <br />
          <p>
            <strong>Administrador:</strong>

            {` Esse tipo de conta administra os projetos e os usuários do sistema.`}
          </p>
        </>
      ),
    });
  }

  function showHelpResetPassword() {
    showModal({
      title: 'Reset de senha',
      content: (
        <>
          <p>
            Se esta opção for marcada, uma nova senha será gerada e aplicada ao
            usuário.
          </p>
        </>
      ),
    });
  }

  function backToUsers() {
    history.push('/admin/users');
  }

  function handleResetPasswordChange() {
    setResetPassword(!resetPassword);
    setRandomPassword(generateRandomPassword());
  }

  async function handleAddUserToProject(data: AddUserToProjectData) {
    if (!data.projectToUser) {
      return;
    }
    setAddingToProject(true);
    await api
      .post('projects/users', {
        project_id: data.projectToUser,
        users: [
          {
            user_id: userEditing.id,
          },
        ],
      })
      .then(() => {
        const project = projects.find(prj => {
          return prj.id === data.projectToUser;
        });
        if (project) {
          const newUserProjects: UserProjects = {
            project_id: project.id,
            user_id: userEditing.id,
            project: {
              project_name: project.project_name,
              company_name: project.company_name,
              id: project.id,
            },
          };
          setUserProjects([newUserProjects, ...userProjects]);
        }
      })
      .finally(() => setAddingToProject(false));
  }

  async function handleRemoveFromProject(project_id: string) {
    setUserProjectsRemove(
      userProjectsRemove.map(userProjectRemove => {
        if (userProjectRemove.project_id === project_id) {
          return {
            project_id,
            removing: true,
          };
        }
        return userProjectRemove;
      }),
    );
    await api
      .delete('projects/users', {
        data: {
          project_id,
          users: [
            {
              user_id: userEditing.id,
            },
          ],
        },
      })
      .then(() => {
        setUserProjects(
          userProjects.filter(userProject => {
            if (userProject.project_id === project_id) {
              return false;
            }
            return true;
          }),
        );
        setUserProjectsRemove(
          userProjectsRemove.filter(userProjectRemove => {
            if (userProjectRemove.project_id === project_id) {
              return false;
            }
            return true;
          }),
        );
      });
  }

  return (
    <>
      <ContainerGlobal>
        <Menu items={AdminMenuObjects.items} />
        <DashboardContainer>
          <DashboardTopMenu />
          <Whitebox>
            <h1>Adicionar usuário a um projeto</h1>
            <Form onSubmit={handleAddUserToProject}>
              <Select
                name="projectToUser"
                id="projectToUser"
                label="Escolha o projeto"
                placeholder="Escolha o projeto"
                options={projects.map(project => {
                  return {
                    value: project.id,
                    label: `${project.company_name} - ${project.project_name}`,
                  };
                })}
              />
              <Button
                type="submit"
                className="btn btn-primary btn-center"
                isLoading={addingToProject}
                style={{ maxWidth: 450 }}
              >
                Adicionar usuário ao projeto selecionado
              </Button>
            </Form>
          </Whitebox>
          <Whitebox>
            <h1>Projetos atribuídos ao usuário</h1>
            <ProjectUserContainer>
              {loadingUserProjects && !userProjects.length && (
                <LoadingIcon>
                  <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                </LoadingIcon>
              )}
              {!userProjects.length && !loadingUserProjects && (
                <p>Não existem projetos atribuídos a este usuário.</p>
              )}
              {userProjects &&
                userProjects.map(userProject => {
                  const removing = userProjectsRemove.find(
                    userProjectRemove => {
                      return (
                        userProjectRemove.project_id ===
                          userProject.project_id &&
                        userProjectRemove.removing === true
                      );
                    },
                  );
                  return (
                    <ProjectUser key={userProject.project.id}>
                      {`${userProject.project.company_name} - ${userProject.project.project_name}`}
                      {removing && (
                        <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                      )}
                      {!removing && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          fixedWidth
                          onClick={() => {
                            handleRemoveFromProject(userProject.project_id);
                          }}
                        />
                      )}
                    </ProjectUser>
                  );
                })}
            </ProjectUserContainer>
          </Whitebox>
          <Whitebox>
            <h1>Editar usuário</h1>
            {!userEditing.id && (
              <>
                <p>Usuário não encontrado.</p>
                <Button onClick={backToUsers}>
                  Voltar ao gerenciamento de usuários
                </Button>
              </>
            )}
            {userEditing.id && (
              <Form onSubmit={handleSubmit} ref={editUserFormRef}>
                <Input2
                  name="name"
                  id="name"
                  label="Nome do usuário"
                  defaultValue={userEditing.name}
                />
                <Input2
                  name="email"
                  id="email"
                  label="E-mail"
                  defaultValue={userEditing.email}
                />
                <InputCheckboxContainer>
                  <InputCheckbox
                    type="checkbox"
                    name="client"
                    id="client"
                    label="Cliente"
                    isChecked={!!userEditing.client}
                  />
                  <InputCheckbox
                    type="checkbox"
                    name="admin"
                    id="admin"
                    label="Administrador"
                    isChecked={!!userEditing.admin}
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    style={{ cursor: 'pointer' }}
                    onClick={showHelpAccountType}
                  />
                  {editCheckboxError.length > 0 && <p>{editCheckboxError}</p>}
                </InputCheckboxContainer>
                <InputCheckboxContainer>
                  <InputCheckbox
                    name="reset_password"
                    id="reset_password"
                    label="Resetar a senha do usuário e gerar uma aleatória?"
                    isChecked={resetPassword}
                    onChange={handleResetPasswordChange}
                  />
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    style={{ cursor: 'pointer' }}
                    onClick={showHelpResetPassword}
                  />
                </InputCheckboxContainer>
                {resetPassword && (
                  <Input2
                    name="password"
                    id="password"
                    label="Nova senha"
                    defaultValue={randomPassword}
                  />
                )}
                <Button
                  type="submit"
                  className="btn btn-primary btn-center"
                  isLoading={editingUser}
                  isErrored={editingError}
                >
                  Alterar usuário
                </Button>
                {editUserError && <FormError>{editUserError}</FormError>}
              </Form>
            )}
          </Whitebox>
        </DashboardContainer>
      </ContainerGlobal>
    </>
  );
};

export default AdminUser;
